'use strict';

const utils = require('../components/utils');

const $mainPDPGallery = $('.product-gallery--main');

/**
 * Reset (hide) model description
 */
function resetModelDescription() {
    $('.js-onmodel-description').removeClass('d-inline-flex').addClass('d-none');
    $('.js-onmodel-attributes > span').each(function () {
        $(this).addClass('d-none');
    });
}

/**
 * Initialize model data
 * @param {string} onModelValue: Reference to data model of thumbnail image
 * @returns {boolean} True if no errors occur
 */
function getOnModelData(onModelValue) {
    let modelKey;
    let modelValue;
    let modelObject;
    const url = $mainPDPGallery.data('model-url');
    try {
        const onModelObj = onModelValue && JSON.parse(decodeURIComponent(onModelValue));
        if (onModelObj) {
            modelKey = Object.keys(onModelObj)[0];
            modelValue = onModelObj[modelKey];
            modelObject = {
                modelKey: modelKey,
                modelValue: modelValue
            };
            $.ajax({
                url: utils.setUrlKeyValue(url, 'pid', onModelObj.variantUPC.trim()),
                type: 'post',
                dataType: 'json',
                data: modelObject,
                success: function (data) {
                    if (data && data.modelData && data.objModelSizeFit) {
                        let dataSizeFit = data.objModelSizeFit.split(',');
                        $('.js-onmodel-description').removeClass('d-none');
                        for (let i = 0; i < dataSizeFit.length; i++) {
                            if (data && data.modelData && data.modelData[dataSizeFit[i]]) {
                                let $sizeFitClause = $('.js-onmodel-description .' + dataSizeFit[i]);
                                let $sizeFitValue = $('.js-onmodel-description .model-' + dataSizeFit[i]);

                                $sizeFitClause.removeClass('d-none');
                                $sizeFitValue.html(' <strong>' + data.modelData[dataSizeFit[i]] + '</strong>');

                                if (dataSizeFit.length - 1 === i) {
                                    $sizeFitValue.append('.');
                                } else if (dataSizeFit.length - 2 === i) {
                                    $sizeFitValue.append(' ' + $('input.model-default-and').val());
                                } else {
                                    $sizeFitValue.append(', ');
                                }
                            }
                        }
                    } else {
                        $('.js-onmodel-description').addClass('d-none');
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    } catch (e) {
        return false;
    }
    return true;
}

/**
 * Initialize model data
 * @returns {string} modeldataValue on model data value of image
 */
function imageModelData() {
    let modelImg = $mainPDPGallery.find('.js-model-image');
    let modelDataValue;

    if (modelImg) {
        let modelImgArray = Array.from(modelImg);
        modelDataValue = modelImgArray.find(function (element) {
            return $(element).data('onmodel') !== null;
        });
    }
    return modelDataValue;
}

/**
 * Trigger display of OnModel description
 * Dependency: imageModelData()
 */
function triggerOnModelDescription() {
    const modelValue = imageModelData();
    const imageclick = $(modelValue).data('onmodel');

    resetModelDescription();

    $mainPDPGallery.trigger('modeldata', {
        imageclick: imageclick
    });
}

module.exports = {
    getOnModelData: getOnModelData,
    imageModelData: imageModelData,
    triggerOnModelDescription: triggerOnModelDescription
};
