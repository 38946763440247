'use strict';

module.exports = function () {
    /**
     * overflowSlider - a jQuery plugin that allows for content to be dragged by
     * the mouse or by touch if it overflows the container.
     *
     * Struction of the markup:
     *     <div class="overflow-slider">
     *         <div class="ofs__container">
     *             <div class="ofs__slider">
     *                 <div>Item</div>
     *                 <div>Item</div>
     *                 <div>...</div>
     *             </div>
     *         </div>
     *     </div>
     *
     * @param {Object} options JSON object containing optional settings.
     * @return {Object} jQuery object of the selected element.
     */
    $.fn.overflowSlider = function (options) {
        var settings = $.extend({}, $.fn.overflowSlider.defaults, options);

        var collection = this;

        // TODO: define these per instance, not on the collection.
        var containerSelector = '.' + settings.containerClass;
        var sliderSelector = '.' + settings.sliderClass;
        var arrowLeftSelector = '.' + settings.arrowLeftClass;
        var arrowRightSelector = '.' + settings.arrowRightClass;

        // functions (avoid no-use-before-define lint errors)
        var slideByAmount;
        var slideToItem;
        var slideToX;
        var dragStart;
        var dragging;
        var dragEnd;
        var toggleArrows;
        var listenForArrowClicks;
        var setHeight;
        var init;
        var clamp;

        /**
         * Slides the slider by the given percentage of the overflow-slider.
         * @param {Object} $ofs This instance of the overflow slider.
         * @param {number} percentToSlide The amount to slide the slider within the container. 0 - 1.
         */
        slideByAmount = function ($ofs, percentToSlide) {
            var newXPos = 0;
            var endX = $ofs.prop('ofs').endX;
            var startXPos = parseFloat($ofs.find(sliderSelector).css('left')) || 0; // parse float to drop the 'px'
            var containerSize = $ofs.find(containerSelector).innerWidth();
            var sliderSize = $ofs.find(sliderSelector).outerWidth(true);
            if ($ofs.prop('ofs').settings.centered && containerSize > sliderSize) {
                // center the slider within the container
                newXPos = (containerSize - sliderSize) / 2;
                $ofs.find(sliderSelector).stop().animate({ left: newXPos }, {
                    always: function () {
                        toggleArrows($ofs, newXPos, 0, endX);
                    }
                });
            } else {
                var slideSize = $ofs.innerWidth() * percentToSlide;
                newXPos = startXPos + slideSize;
                slideToX($ofs, newXPos);
            }
        };

        /**
         * Slide the slider to the specified item if it is overflowing the slider.
         * @param {Object} $ofs This instance of the overflow slider.
         * @param {Object} $item jQuery object of the item within the slider.
         */
        slideToItem = function ($ofs, $item) {
            var itemLeft = $item.position().left;
            var $slider = $ofs.find(sliderSelector);
            var currentSliderXPos = parseFloat($slider.css('left')) || 0;
            var sliderMargin = parseFloat($slider.css('margin-left'));
            // var endX = $ofs.prop('ofs').endX;
            var arrowSize = 0;
            if ($ofs.prop('ofs').settings.showArrows) {
                // add the width of the arrows
                arrowSize = $ofs.find(arrowRightSelector).outerWidth(true) || $ofs.find(arrowLeftSelector).outerWidth(true) || 0;
            }

            var containerSize = $ofs.find(containerSelector).innerWidth();
            var sliderSize = $ofs.find(sliderSelector).outerWidth(true);
            if ($ofs.prop('ofs').settings.centered && containerSize > sliderSize) {
                // center the slider within the container
                var newXPos = (containerSize - sliderSize) / 2;
                var endX = $ofs.prop('ofs').endX;
                $ofs.find(sliderSelector).stop().animate({ left: newXPos }, {
                    always: function () {
                        toggleArrows($ofs, newXPos, 0, endX);
                    }
                });
            } else if (itemLeft + currentSliderXPos < 0 + arrowSize) { // if the item is to the left
                slideToX($ofs, (itemLeft * -1) - (sliderMargin - arrowSize));
            } else {
                var itemRight = itemLeft + $item.outerWidth(true);
                var ofsWidth = $ofs.find(containerSelector).innerWidth();
                if (itemRight + currentSliderXPos + sliderMargin > ofsWidth - arrowSize) { // if the item is to the right
                    slideToX($ofs, (ofsWidth - itemRight - sliderMargin - arrowSize));
                }
            }
        };

        /**
         * Slide the slider to the specified x position.
         * @param {Object} $ofs This instance of the overflow slider.
         * @param {number} x The slider left position to slide to.
         */
        slideToX = function ($ofs, x) {
            var endX = $ofs.prop('ofs').endX;
            var newXPos = clamp(x, endX, 0);
            $ofs.find(sliderSelector).stop().animate({ left: newXPos }, {
                always: function () {
                    toggleArrows($ofs, newXPos, 0, endX);
                }
            });
        };

        /**
         * when the user initiates a mouse click or touch dragging event
         * @param {Event} e The event that triggered the start of a drag.
         */
        dragStart = function (e) {
            if (e.type !== 'touchstart') {
                e.preventDefault();
            }
            // 'this' is the .overflow-slider element
            var $ofs = $(this);
            if ($ofs.prop('ofs').settings.centered) {
                var containerSize = $ofs.find(containerSelector).innerWidth();
                var sliderSize = $ofs.find(sliderSelector).outerWidth(true);
                if (containerSize > sliderSize) {
                    // The slider fits within the container. No dragging needed.
                    return;
                }
            }

            // mouse is down on the slider. get data.
            if (e.type === 'touchstart') {
                this.ofs.mouseX = e.touches[0].clientX;
            } else {
                this.ofs.mouseX = e.clientX;
            }
            this.ofs.sliderX = parseFloat($ofs.find(sliderSelector).css('left')) || 0;

            // start listening for movement
            $ofs.on('mousemove.ofs', dragging);
            $ofs.on('touchmove.ofs', dragging);
            // $ofs.on('pointermove.ofs', dragging); // for IE

            // start listening for a mouse up anywhere on the page
            $(document).one('mouseup.ofs', dragEnd);
            $(document).one('touchend.ofs', dragEnd);
            // $(document).one('pointerup.ofs', dragEnd); // for IE
        };

        /**
         * "on dragging" functionality that takes in either touch event or on click event types and updates the x position of the slider within the container.
         * @param {Event} e The event triggering the drag.
         */
        dragging = function (e) {
            e.preventDefault();
            // 'this' is the .overflow-slider element
            var $ofs = $(this);
            this.ofs.isDragging = true;
            var clientX;
            if (e.type === 'touchmove') {
                clientX = e.touches[0].clientX;
            } else {
                clientX = e.clientX;
            }
            // update the left postion
            var currentSliderXPos = this.ofs.sliderX;
            var mouseDownXPos = this.ofs.mouseX;
            var endX = this.ofs.endX;
            var newXPos = currentSliderXPos + (clientX - mouseDownXPos);
            newXPos = clamp(newXPos, endX, 0);
            $ofs.find(sliderSelector).css('left', newXPos);
        };

        /**
         * This is called when the user releases the mouse click or touch dragging event
         * @param {Event} e The mouseup/touchend event.
         */
        dragEnd = function () {
            // 'this' is document
            // stop listening for movement
            $(collection).off('mousemove.ofs');
            $(collection).off('touchmove.ofs');
            // $ofs.off('pointermove.ofs'); // for IE

            $(collection).each(function () {
                if (this.ofs.isDragging) {
                    this.ofs.isDragging = false;
                    var newXPos = parseFloat($(this).find(sliderSelector).css('left')) || 0;
                    var endX = this.ofs.endX;
                    // this.ofs.sliderX = newXPos;
                    toggleArrows($(this), newXPos, 0, endX);
                } else {
                    // mouse did not move. this is a regular click.
                    this.ofs.isDragging = false;
                }
            });
        };

        /**
         * Uses the x position of the slider to hide or show the left and right arrows.
         * If we're within 3px (see threshold) of the left or right position, the arrow will be hidden.
         * @param {Object} $ofs This instance of the overflow slider.
         * @param {number} x The current x position of the slider.
         * @param {number} left The minimum x position of the slider.
         * @param {number} right The maximum x position of the slider.
         */
        toggleArrows = function ($ofs, x, left, right) {
            // threshold is an arbitrary minimum distance the slider must travel
            // before the arrows will show. If the slider is within 3px of the min
            // or max, the arrow will not show.
            var threshold = 3;
            $ofs.find(arrowLeftSelector).toggle(x < left - threshold);
            $ofs.find(arrowRightSelector).toggle(x > right + threshold);
        };

        /**
         * this binds a click event to the arrows. The settings for slideAmount are defined in the .overflowSlider.defaults
         * @param {Object} $ofs This instance of the overflow slider.
         */
        listenForArrowClicks = function ($ofs) {
            var slideAmount = $ofs.prop('ofs').settings.slideAmount;
            $ofs.on('click.ofs', arrowLeftSelector, function () {
                slideByAmount($ofs, slideAmount); // slide 75% of the swiper width to the right (positive is right)
            });
            $ofs.on('click.ofs', arrowRightSelector, function () {
                slideByAmount($ofs, (-1 * slideAmount)); // slide 75% of the swiper width to the left (negative is left)
            });
        };

        /**
         * Set the height of the slider container.
         * @param {Object} $ofs This instance of the overflow slider.
         * @param {Object} instanceSettings Options this instance of the overflow slider; if undefined, $.extend will be ignore it
         */
        setHeight = function ($ofs, instanceSettings) {
            var sliderHeight = $ofs.find(sliderSelector).outerHeight();
            var $sliderContainer = $ofs.find(containerSelector);
            settings = $.extend({}, $.fn.overflowSlider.defaults, instanceSettings);

            if (settings.expectedHeight && parseInt(settings.expectedHeight, 10) > 0) {
                $sliderContainer.css('height', settings.expectedHeight);
            } else if (sliderHeight) {
                $sliderContainer.css('height', sliderHeight);
            } else {
                // oh well, we tried.
                // the height is still 0 and we don't have an expectedHeight. Is the slider hidden?
            }
        };

        /**
         * Initialiizing function that establishes defaults for each instance of selected colletion.
         * @param {Object} $ofs This instance of the overflow slider.
         */
        init = function ($ofs) {
            var instanceSettings = settings;
            // get settings from a data attribute
            // this will override any settings set in JS.
            if ($ofs.data('ofs-options')) {
                // var attrOptions = JSON.parse($ofs.data('ofs-options')); // TODO: error handling
                var attrOptions = $ofs.data('ofs-options');
                instanceSettings = $.extend({}, settings, attrOptions);
            }

            var elem = $ofs[0]; // this is needed to avoid the no-param-reassign eslint error.

            // var itemSelector = '.' + instanceSettings.itemClass;
            elem.containerSelector = '.' + instanceSettings.containerClass;
            elem.sliderSelector = '.' + instanceSettings.sliderClass;
            elem.arrowLeftSelector = '.' + instanceSettings.arrowLeftClass;
            elem.arrowRightSelector = '.' + instanceSettings.arrowRightClass;

            var endX = $ofs.find(elem.containerSelector).innerWidth() - $ofs.find(elem.sliderSelector).outerWidth(true); // had to place this value here because endX was diplaying the incorrect value or NaN value

            $ofs.addClass('overflow-slider'); // in case a different selector was used.

            // the slider is positioned absolutely, so the $ofs has a height of 0.
            // it should match the height of the slider.
            setHeight($ofs, instanceSettings);
            var $imgsInSlider = $ofs.find(elem.sliderSelector).find('img');
            var imgLoadedTimeoutID;
            $imgsInSlider.each(function () {
                if (!this.complete) {
                    // the image has not finished loading.
                    $(this).one('load', function () {
                        clearTimeout(imgLoadedTimeoutID);
                        imgLoadedTimeoutID = setTimeout(function () {
                            elem.ofs.setHeight($ofs);
                        }, 100);
                    });
                }
            });

            // elem.ofs = $.extend({}, $ofs.prop('ofs'), {
            elem.ofs = {
                settings: instanceSettings,
                isDragging: false,
                sliderX: 0, // keep track of the x position of the slider
                mouseX: null, // the x position of the mouse at the time of the last mouseDown
                endX: endX, // the x position of the slider when it is slid to the left. 0 is the start, the right position.
                clickedTab: null, // record clicked tab, so we can activate it if we don't drag.
                init: init,
                setHeight: setHeight,
                toggleArrows: toggleArrows,
                slideToX: slideToX,
                slideToItem: slideToItem
            };

            var $items = $ofs.find(elem.sliderSelector).children();
            $items.addClass(instanceSettings.itemClass);

            if (instanceSettings.showArrows) {
                // check for arrows, add if not found.
                if ($ofs.find(elem.arrowRightSelector).length < 1) {
                    var arrowBtns = `
                        <button aria-label="${instanceSettings.arrowLeftLabel}" class="${instanceSettings.arrowClass} ${instanceSettings.arrowLeftClass}"></button>
                        <button aria-label="${instanceSettings.arrowRightLabel}" class="${instanceSettings.arrowClass} ${instanceSettings.arrowRightClass}"></button>
                    `;
                    $ofs.append(arrowBtns);
                }
                listenForArrowClicks($ofs);

                if (instanceSettings.alignArrowsToImgs) {
                    // try to center the left/right arrows on the images
                    var firstImg = $ofs.find('img:first');
                    if (firstImg) {
                        var imgHeight = firstImg.height();
                        $ofs.find('.' + instanceSettings.arrowClass).css('top', imgHeight / 2);
                    }
                }
            }

            setTimeout(function () {
                // ensure the slider is properly positioned at the start.
                var $activeItem = $ofs.find('.' + instanceSettings.activeItemClass);
                if ($activeItem.length) {
                    slideToItem($ofs, $activeItem);
                } else {
                    slideByAmount($ofs, 0);
                }
            }, 0);
        };

        /**
         * Resize listener to re-initalize the sliders, and to adjust position if necessary.
         */
        var windowResizeTimeoutId;
        var resizeHandler = function () {
            clearTimeout(windowResizeTimeoutId);
            windowResizeTimeoutId = setTimeout(function () {
                $(collection).each(function () {
                    var $ofs = $(this);
                    init($ofs);
                });
            }, 500);
        };
        $(window).on('resize.ofs', resizeHandler);

        /**
         * This constrains x btween two numbers. min <= x <= max
         * @param {number} x The number to clamp within the min and max
         * @param {number} min The minimum allowed value
         * @param {number} max The maximum allowed value
         * @return {number} x, clamped between min and max. This will be equal to min, x, or max.
         */
        clamp = function (x, min, max) {
            var c = Math.min(Math.max(x, min), max);
            return c;
        };

        /**
         * This is the start of the functionality which supports multiple instances of .overflowSlider.
         * "this" is a jQuery object containing one or more matching element. For each element, an overflow slider is created.
         */
        return this.each(function () {
            // "this" is one of the selected elements
            var $ofs = $(this);

            init($ofs); // why is this miscalculating the endX?
            // setTimeout(init($ofs), 0); // why do I need to init twice?!

            // on mouse down within the overflowSlider...
            $ofs.on('mousedown.ofs', dragStart);
            $ofs.on('touchstart.ofs', dragStart);
            // $ofs.on('pointerdown.ofs', dragStart); // for IE

            var itemSelector = '.' + $ofs.prop('ofs').settings.itemClass;
            $ofs.on('focus', itemSelector, function () {
                slideToItem($ofs, $(this));
            });

            return this;
        });
    };

    /**
     * Default settings for the overflow sliders.
     */
    $.fn.overflowSlider.defaults = {
        arrowClass: 'ofs__arrow',
        arrowLeftClass: 'ofs__arrow--left',
        arrowRightClass: 'ofs__arrow--right',
        arrowLeftLabel: 'Slide Right',
        arrowRightLabel: 'Slide Left',
        containerClass: 'ofs__container',
        sliderClass: 'ofs__slider',
        itemClass: 'ofs__item',
        showArrows: true,
        alignArrowsToImgs: false, // attempt to align left/right arrows to the images
        slideAmount: 0.75, // slide up to 75% of the container width when left/right buttons are clicked
        centered: true, // when the slider fits within the container, should it be centered or left-aligned?
        activeItemClass: 'active', // active and selected item at overflowSlider
        expectedHeight: 0 // set to the approximate height of the slider content, to prevent a 0 height slider when the height is measured before content has finished loading.
    };

    /**
     * For each element having the "overflow-slider" class, initialize the overflowSlider
     * with the default settings.
     */
    $(function () {
        // set up the overflow sliders
        // this one line will initialize multiple sliders all having the same settings.
        $('.overflow-slider').overflowSlider();
    });
};

