let reservationInterval;
let $timerBanner;
let dangerZone = 60; // change to a danger theme at this time.
let messageBufferTime = 5; // time to account for page loading when time passes.
let expireTime;
let remainingSeconds;

const destroyTimer = () => {
    clearInterval(reservationInterval);
    reservationInterval = null;
    expireTime = null;
    remainingSeconds = null;
    if (window.resources && window.resources.reservation && window.resources.reservation.reservationSecondsRemaining) {
        delete window.resources.reservation.reservationSecondsRemaining;
    }
    if ($timerBanner && $timerBanner.length) {
        $timerBanner.close();
    }
};

const getRemainingSeconds = () => {
    if (expireTime) {
        let now = new Date().getTime();
        let secs = parseInt((expireTime - now) / 1000, 10);

        return secs;
    }

    return 0;
};

const popBanners = () => {
    if (window.resources && window.resources.reservation && window.resources.reservation.messages) {
        // check each message for a matching time.
        let msgKeys = Object.keys(window.resources.reservation.messages);
        for (let i = 0; i < msgKeys.length; i++) {
            let msgKey = msgKeys[i];
            let msg = window.resources.reservation.messages[msgKey];

            let startTime = typeof msg.startTime !== 'undefined' ? msg.startTime : remainingSeconds; // display it immediately if startTime is undefined.
            let endTime = startTime - messageBufferTime;
            if ((remainingSeconds <= startTime && remainingSeconds > endTime) || typeof msg.startTime === 'undefined') {
                // show the banner:
                let html = `<p class="font-weight-bold">${msg.title}</p>
                            <p>${msg.message}</p>
                            ${msg.additionalContent || ''}`;
                $.alert({
                    content: html,
                    placement: 'banner',
                    theme: msg.theme,
                    id: msgKey,
                    containerId: 'reservationBannerContainer',
                    timeToLive: 10000
                });
                // we've used the message, so remove it from the list
                delete window.resources.reservation.messages[msgKey];
            }
        }
    }
};

const createTimeString = () => {
    let mins = Math.floor(remainingSeconds / 60);
    let secs = remainingSeconds % 60;
    if (secs < 10) {
        secs = '0' + secs; // convert to a string so we can have two digits. 9 => '09'
    }
    return `${mins}:${secs}`;
};

const updateReservationTimer = () => {
    remainingSeconds = getRemainingSeconds();

    if (remainingSeconds >= 0) {
        $('.reservation__time').text(createTimeString());

        if (remainingSeconds <= dangerZone) {
            // change the theme from 'info' to 'danger'
            $('#reservationTimer.alert--info').toggleClass('alert--info alert--danger');
        } else {
            $('#reservationTimer.alert--danger').toggleClass('alert--info alert--danger');
        }

        popBanners();
    } else {
        destroyTimer();
    }
};

const startTimer = () => {
    expireTime = new Date().getTime() + parseInt(window.resources.reservation.reservationSecondsRemaining * 1000, 10);

    if (!reservationInterval) {
        reservationInterval = setInterval(updateReservationTimer, 1000);
    }

    if (!$timerBanner || !$timerBanner.length) { // $timerBanner might be undefined, empty jquery object (truthy), or a valid jquery object (length 1)
        remainingSeconds = getRemainingSeconds();
        if (remainingSeconds >= 0) { // when remainingSeconds === 0, we still want to display '0:00'
            let initialTimeStr = createTimeString();
            let timeRemainingMessage = window.resources.reservation.timeRemainingMessage;
            $timerBanner = $.alert({
                content: `<div class="reservation-timer">${timeRemainingMessage} <strong class="reservation__time">${initialTimeStr}</strong></div>`,
                placement: 'banner',
                theme: window.resources.reservation.reservationSecondsRemaining <= dangerZone ? 'danger' : 'info',
                id: 'reservationTimer',
                containerId: 'reservationBannerContainer',
                autoClose: false
            });
        }
    }
};

const init = () => {
    if (window.resources && window.resources.reservation && window.resources.reservation.reservationSecondsRemaining) {
        startTimer();
    }
    // remove any existing banners.
    $('#reservationBannerContainer').find('.alert').not('#reservationTimer').remove();
    popBanners();
};

const updateReservationData = (response) => {
    if (response && response.reservation) {
        if (window.resources) {
            if (window.resources.reservation) {
                window.resources.reservation.messages = $.extend({}, window.resources.reservation.messages, response.reservation.messages);
                window.resources.reservation.reservationSecondsRemaining = response.reservation.reservationSecondsRemaining;
                remainingSeconds = parseInt(response.reservation.reservationSecondsRemaining, 10);
            } else {
                window.resources.reservation = response.reservation;
            }
        } else {
            window.resources = {
                reservation: response.reservation
            };
        }
        init();
    }
};

const getDestroyTimer = () => {
    return destroyTimer;
};

module.exports = {
    init,
    getDestroyTimer,
    updateReservationData
};
