'use strict';

var Cookies = global.Cookies = require('js-cookie/src/js.cookie');
var sizefitJs = require('../sizefit/sizefit');

/**
 * Checks if the modal content will be the size fit guide, if so grab the contents and insert them into the modal body
 * @param {JQuery} $modalContentWrapper JQuery object that represents the root node of the modal
 * @returns {int} length of data attribute value
 */
function isSizeFitModal($modalContentWrapper) {
    return $modalContentWrapper.find('.modal-body').data('sizefitguide-url');
}

/**
 * Sets up the event behavior and shows the modal with the desired content
 * @param {JQuery} $modalContentWrapper JQuery object that represents the root node of the modal
 */
function triggerModal($modalContentWrapper) {
    $modalContentWrapper
        .on('show.bs.modal', function () {
            var $this = $(this);

            $this.find('.modal-dialog').css({
                height: 'auto'
            });

            if (isSizeFitModal($this)) {
                sizefitJs.getSizeFitGuideContent($this, $this.find('.modal-body').data('sizefitguide-url'));
            }
        }).on('hidden.bs.modal', function () {
            var $this = $(this);

            // reset modal body innerHTML if the content we retrieved required Ajax
            if (isSizeFitModal($this)) {
                $this.find('.modal-body').html('');
            }

            // remove the modal markup completely from the DOM
            $this.remove();

            // also remove the backdrop
            $('.modal-backdrop').remove();
        });
    $modalContentWrapper.appendTo('body');
    $modalContentWrapper.modal('show');
}

module.exports = {
    init: function () {
        var pageLoadModalClassName = 'js-modal-on-load';
        var onClickModalClassName = 'js-modal-on-click';
        var domain = window.location.host.replace('www.', '');

        // check if we're in AEM by looking at the domain.
        // PROD, STAGE: author-columbiasc-XXX-64.adobecqms.net
        // DEV, QA: aemXXX.colmdev.com:4502
        // SANDBOX: localhost:4502
        if (domain.indexOf('adobe') > -1 || domain.indexOf(':4502') > -1) {
            // Just show all the modals. (don't set cookies)
            $('.' + pageLoadModalClassName).modal('show');
        } else {
            /**
             * Show a Boostrap modal on page load.
             * HTML should be structured this way:
             *      <div class="modal js-modal-on-load" role="dialog"
             *          data-cookie="laborDaySaleModal"
             *          data-expires="session">
             *          ...
             *      </div>
             *
             * data-cookie is the name of the cookie. We can have multiple page-load modals, but only one will be displayed per page load.
             * data-expires can be number of days until the cookie expires, or 0 or 'session' or omitted for a session cookie.
             *
             * In the above example, the laborDaySaleModal will be displayed once per session.
             *
             * Another example:
             *      <div class="modal js-modal-on-load" role="dialog"
             *          data-cookie="loyaltySignUpModal"
             *          data-expires="20">
             *          ...
             *      </div>
             *
             * The loyaltySignUpModal will be displayed once per 20 days. If both of these modals exist on the page, only the first modal
             * that does not have a cookie will be shown. On the next page load, the first modal will be cookied, so the next modal will be shown.
             */
            $('.' + pageLoadModalClassName).each(function () {
                // get data-cookie
                var $this = $(this);
                var cookieName = $this.data('cookie') || 'unnamedModal';
                var cookieDays = $this.data('expires') || 'session'; // days until cookie expires (or 'session')
                var cookieOptions = {
                    // path: '/', // the default path is '/'.
                    domain: domain
                };
                if (cookieDays !== 'session') {
                    cookieOptions.expires = cookieDays;
                } // if cookieDays IS 'session', do NOT set expires on the cookieOptions object.

                if (Cookies.get(cookieName)) { // eslint-disable-line
                    // cookie already exists. skip this modal.
                    return true; // continue
                }

                triggerModal($this);

                // set the cookie
                Cookies.set(cookieName, 1, cookieOptions); // eslint-disable-line

                // skip all other .js-modal-on-load
                return false; // break
            });
        }

        /**
         * Show a Bootstrap modal upon click of a trigger element.
         * HTML should be structured this way:
         *      <a href="#myModal" id="someLink">Click Me</a>
         *      <div id="myModal" class="modal js-modal-on-click" role="dialog"
         *          data-trigger="#someLink">
         *          ...
         *      </div>
         *
         * Above, the modal and trigger are associated by the data-trigger attribute.
         * The modal will be displayed each time #someLink is clicked.
         *
         *      <a href="#myModal" class="js-modal-on-click" data-target="#myModal">Click Me</a>
         *      <div id="myModal" class="modal" role="dialog">
         *          ...
         *      </div>
         *
         * This time, the js-modal-on-click is applied to the trigger. The script recognizes that it is
         * not a modal (does not have the modal class), so it looks for the data-target attribute to
         * connect it to the modal content.
         * Each time the link is clicked, #myModal will be displayed.
         */
        $('.' + onClickModalClassName).each(function () {
            var $this = $(this);
            var $trigger;
            var $target;

            if ($this.hasClass('modal')) {
                // this is the modal - find the trigger element.
                $trigger = $($this.data('trigger'));
                $target = $this;
            } else {
                // this is the trigger - find the element to be the modal.
                $trigger = $this;
                $target = $($this.data('target') || $this.attr('href'));
                $target.addClass('modal');
            }

            // launch the modal when the trigger is clicked.
            $trigger.on('click', function (e) {
                e.preventDefault();

                triggerModal($target);
            });
        });
    }
};
