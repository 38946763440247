'use strict';
/* global _satellite */

/**
 * Toggle alert icon visibility
 * @param {boolean} isProfileComplete determines if progressive profiling is complete
 */
function toggleAlertBadge(isProfileComplete) {
    const $profileIcons = $('.js-profile-icon');

    $profileIcons.each(function () {
        const $icon = $(this);
        const hasAlertWrapper = $icon.parent().hasClass('alert-badge');

        // Apply or remove the wrapper based on profile completion status
        if (!isProfileComplete && !hasAlertWrapper) {
            $icon.wrap('<div class="alert-badge"></div>');
        } else if (isProfileComplete && hasAlertWrapper) {
            $icon.unwrap('.alert-badge');
        }
    });
}

/**
 * Retrieves and sets the profiling progress alert
 */
function setProflingProgressAlert() {
    if ((window.clientData.user && !window.clientData.user.isLoggedIn) || !window.resources.progressiveProfiling.profilingEnabled) {
        return;
    }

    $.ajax({
        url: window.resources.progressiveProfiling.profilingProgress,
        type: 'GET',
        success: function (response) {
            if (response && response.profileProgressData) {
                toggleAlertBadge(response.profileProgressData.isComplete);
            }
        }
    });
}

/**
 * Initialize the Profiling Circular Progress Indicator
 * @param {Objext} $cpi jQuery object of the circular progress indicator
 */
function initProfilingCpi($cpi) {
    if ($cpi && $cpi.length && !$cpi.data('active')) {
        $cpi.data('active', true);
        $cpi.progressArc();
    }

    setProflingProgressAlert();
}

/**
 * Submit Profiling Data in the current Wizard Step
 * @param {JQuery} $step JQuery Object of Wizard Step that triggered data submission
 */
function submitProfilingData($step) {
    // Send category update if the step has a submission url
    let $stepForm = $step.find('form');
    if ($stepForm.length && $stepForm.attr('action')) {
        let data = $stepForm.serialize();
        $.ajax({
            url: $stepForm.attr('action'),
            type: 'POST',
            data: data,
            success: function (response) {
                // Update the profiling CTAs
                if (response && response.profileProgressData) {
                    $('.js-profiling-progress').data('progress', response.profileProgressData.progressDecimal);
                    toggleAlertBadge(response.profileProgressData.isComplete);
                }

                $('.js-profiling-container').trigger('progressiveProfiling:stepCompleted', $step.data('step-id'));
            }
        });
    }
}

/**
 * Creates the Profiling Data Submission success toaster
 */
function getSuccessToaster() {
    $.alert({
        content: `
        <strong>${window.resources.progressiveProfiling.text.title}</strong><br />${window.resources.progressiveProfiling.text.saved}
        `,
        theme: 'success',
        placement: 'toast'
    });
}

/**
 * Creates and loads the profilingDrawer
 * @param {Event} e Event Initiator
 * @param {string} srcUrl source URL value for drawer creation
 * @returns {Object} jQuery object of created drawer
 */
function getProfilingDrawer(e, srcUrl) {
    const $profilingDrawer = $.drawer({
        src: srcUrl,
        size: 'sm',
        returnFocusTo: e ? e.target : 'body'
    });

    return $profilingDrawer;
}

/**
 * Initialize the Progressive Profiling Wizard
 * This can be initiated by opening of a standalone drawer, or within another component
 * @param {Object} $wizardContainer jQuery object of drawer container element
 * @param {Event} initiatingEvent Event Initiator
 * @param {boolean} nextStepAfterInit Whether or not to move to the next step after initialization
 */
function initProfilingWizard($wizardContainer, initiatingEvent, nextStepAfterInit) {
    const $interactiveSteps = $wizardContainer.find('.wizard__step:not([data-exclude-from-step-count])');

    $wizardContainer.wizard({
        initialStep: nextStepAfterInit ? 1 : 0,
        init: function ($wiz) {
            // Sets the number of steps available
            $wiz.find('.js-num-profiling-steps').text($interactiveSteps.length);

            initProfilingCpi($wizardContainer.find('.progress'));

            $wiz.on('click.next', '.js-profiling-wiz-next', function (e) {
                $wiz.nextStep(e);
            });

            // Remove the step from the wizard incase the user goes back to the wizard before reload
            $wiz.on('progressiveProfiling:stepCompleted', function (e, stepId) {
                $wiz.removeStep(stepId);
            });

            if (nextStepAfterInit) {
                // Reset the wizard if drawer closes
                $(document).on('hide.drawer', $wiz.closest('.drawer'), function (e) {
                    $wiz.find('.js-profiling-wiz-next').off('click.next');
                    $wiz.gotoStep(e, 0); // Step 0 is My Account
                });
            }
        },
        beforeStepExit(e, stepId) {
            const $initiator = $(e.currentTarget);
            if ($initiator.length && $initiator.hasClass('js-profiling-wiz-next') && ($initiator.closest('.wizard__step').data('profiling-submit') === true)) {
                submitProfilingData($(this));
            }

            // Trigger a direct call and pass stepId - for analytics tracking via Adobe Launch
            if (typeof _satellite !== 'undefined' && $initiator.length) {
                if ($initiator.hasClass('js-profiling-wiz-next')) {
                    _satellite.track('formComplete', { dataStepId: stepId });
                }
            }
        },
        afterStepEnter(stepId, $wiz) {
            const interactiveStepIndex = $interactiveSteps.index($(this)) + 1 || 1;

            // Update current step number with the interactiveStepIndex
            $wiz.find('.js-current-profiling-steps').text(interactiveStepIndex);

            // Trigger a direct call and pass stepId - for analytics tracking via Adobe Launch
            if (stepId !== 'myAccount' && typeof _satellite !== 'undefined') {
                _satellite.track('formStart', { dataStepId: stepId });
            }

            // If entering last step, adjust steps's submission behavior as beforeStepExit will not be called
            if ($wiz.numSteps === $wiz.steps.indexOf(stepId) + 1) {
                const $step = $(this);
                // Change text of button to be 'Complete'
                $step.find('.js-profiling-wiz-next').text(window.resources.progressiveProfiling.text.completeButton);

                // Set up listener to still submit preferences
                $step.find('.js-profiling-wiz-next').off('click.next');
                $step.one('click', '.js-profiling-wiz-next', function (e) {
                    if ($(e.currentTarget).hasClass('js-profiling-wiz-next')) {
                        submitProfilingData($step);
                    }

                    // Trigger a direct call and pass stepId - for analytics tracking via Adobe Launch
                    const $initiator = $(e.currentTarget);
                    if (typeof _satellite !== 'undefined' && $initiator.length) {
                        if ($initiator.hasClass('js-profiling-wiz-next')) {
                            _satellite.track('formComplete', { dataStepId: stepId });
                        }
                    }

                    const $drawer = $wiz.closest('.drawer');

                    // Close the appropriate drawer
                    if ($drawer.hasClass('account-drawer')) {
                        $drawer.trigger('accountDrawer:hide');
                    } else if ($drawer.length) {
                        $drawer.data('drawer').close();
                    }

                    getSuccessToaster();
                });
            }
        },
        updateProgress: function () {} // Overriding default function
    });
}

/**
 * Load the Profiling Steps into an existing Profiling Wizard
 * @param {Event} e Event Initiator
 */
function loadProfilingStepsIntoExistingWizard(e) {
    const $initiator = $(e.currentTarget);
    if ($initiator.closest('.wizard__steps').children().length > 1
        || $initiator.data('loading')) {
        return; // Exit if Profiling Steps are already loaded or loading
    }

    $initiator.data('loading', true);
    $initiator.closest('.drawer').spinner().start();

    $.ajax({
        url: window.resources.progressiveProfiling.profilingStepsUrl,
        type: 'GET',
        data: {
            excludeWizardContainer: true
        },
        success: function (data) {
            // Add profiling steps to the existing wizard
            const $wizardContainer = $initiator.closest('.js-profiling-wizard');
            const $wizardSteps = $wizardContainer.find('.wizard__steps');
            $wizardSteps.append(data);

            initProfilingWizard($wizardContainer, e, true);
        },
        complete: function () {
            $initiator.data('loading', false);
            $initiator.closest('.drawer').spinner().stop();
        }
    });
}

/**
 * Open the Profiling Drawer
 * @param {Event} e Event Initiator
 * @param {boolean} includeIncompleteCTA Query param to include the incompleteProfileCTA
 */
function openNewDrawer(e, includeIncompleteCTA) {
    let profilingDrawerUrl = window.resources.progressiveProfiling.profilingStepsUrl;

    if (includeIncompleteCTA) {
        profilingDrawerUrl += '?includeIncompleteCTA=true';
    }

    const $profilingDrawer = getProfilingDrawer(e, profilingDrawerUrl);

    $profilingDrawer.on('contentAsyncLoaded.drawer', function () {
        const $wizardContainer = $profilingDrawer.find('.js-profiling-wizard');
        initProfilingWizard($wizardContainer);
    }).on('click', '.js-profiling-drawer-close', function () {
        $profilingDrawer.close();
    });
}

/**
 * Opens the Preference Drawer for editing
 * @param {Event} e Event Initiator
 */
function openEditPreferenceDrawer(e) {
    const preferenceKey = $(e.target).data('profile-preference-key');
    const preferenceDrawerUrl = window.resources.progressiveProfiling.profilingEditPreferenceUrl + '?profilePreferenceKey=' + preferenceKey;

    const $singlePreferenceDrawer = getProfilingDrawer(e, preferenceDrawerUrl);

    // Add event listeners
    $singlePreferenceDrawer.one('progressiveProfiling:stepCompleted', function () {
        location.reload();
    }).on('click', '.js-profiling-submit-preference', function () {
        submitProfilingData($(this).closest('.wizard__step'));
        $.spinner().start();
    });
}

module.exports = {
    init: function () {
        $(document)
            .on('click', '.js-profiling-drawer-open, .my-account-content .js-profiling-wiz-start', function (e) {
                e.preventDefault();
                openNewDrawer(e, false);
            }).on('click', '.account-drawer .js-profiling-wiz-start', function (e) {
                loadProfilingStepsIntoExistingWizard(e);
            }).on('click', '.js-profiling-edit-preference', function (e) {
                openEditPreferenceDrawer(e);
            })
            // Sets progress alert before entering account drawer to handle mobile menu profile icon experience
            .on('mobileMenu:show', function () {
                setProflingProgressAlert();
            });

        // On successful Account Registration, load the Profiling Drawer
        if (window.resources && window.resources.progressiveProfiling && window.resources.progressiveProfiling.profilingEnabled
                && sessionStorage.getItem('isFirstLogin') && !sessionStorage.getItem('reloaded')) {
            openNewDrawer(null, true);
        }

        // A step has been updated, progress should be updated
        $(document).on('progressiveProfiling:stepCompleted', '.js-profiling-container', function () {
            let $profilingProgress = $('.js-profiling-progress');
            let percentComplete = $profilingProgress.data('progress');

            // Update each progress indicator on the page
            $profilingProgress.each((i, progress) => {
                progress.updateProgress({
                    progress: percentComplete,
                    color: '#238832'
                });
            });

            // If we complete all steps, remove any CTAs
            if (percentComplete >= 1) {
                let profilingCTA = $('.js-profiling-cta');
                profilingCTA.remove();
            }
        });

        // Initialize the CPIs on load
        $('.progress.js-profiling-progress').each((i, cpi) => { initProfilingCpi($(cpi)); });
    },
    initProfilingCpi: initProfilingCpi
};
