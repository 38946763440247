'use strict';

const basketHelper = require('../helper/basketHelper');
const breakpoints = require('../components/breakpoints');
const utils = require('../components/utils');
const keyboardAccessibility = require('./keyboardAccessibility');

let minicartInDrawer = null;
let minicartDropdownDisabled = null;
let $minicartDrawer;

/**
 * Private Function: Dropdown is disabled on the Cart page only.
 * @returns {boolean} - If true, dropdown is disabled.
 */
function isDropdownDisabled() {
    if (minicartDropdownDisabled === null) {
        minicartDropdownDisabled = $('.js-minicart-preferences').data('minicart-dropdown-disabled') === true;
    }
    return minicartDropdownDisabled;
}

/**
 * Whether the minicart is displayed in a drawer
 * @returns {boolean} Whether or not to display in a drawer
 */
function useDrawerElement() {
    if (minicartInDrawer === null) {
        minicartInDrawer = $('.minicart-link').data('element') === 'drawer';
    }
    return minicartInDrawer;
}

/**
 * offset the minicart with the minicart footer, minicart header and global header
 */
function offsetMiniCart() {
    const minicartHeaderOffset = ($('.js-action__minicart .minicart-content__header').outerHeight() || 0) + ($('.forecasted-loyalty-rewards').outerHeight() || 0);
    const minicartFooterOffset = $('.js-action__minicart .minicart-content__footer').outerHeight() || 0;
    const minicartContentMaxHeight = parseFloat($('.js-action__minicart .minicart-content').css('max-height')) || ($(window).height() - $('.siteheader').outerHeight());
    const totalOffset = minicartContentMaxHeight - minicartFooterOffset - minicartHeaderOffset;
    $('.minicart-content__products').css('max-height', totalOffset);
    if (breakpoints.isLowRes()) {
        $('.minicart-content__products').css('min-height', totalOffset);
    }
}

/**
 * Private Function: Close minicart
 */
function hideMiniCart() {
    if (useDrawerElement()) {
        if ($minicartDrawer) {
            $minicartDrawer.close();
        }
    } else {
        utils.removeBackdrop();
        $('html').removeClass('js-minicart-shown');
        $('.js-action__minicart').removeClass('show js-popover-shown');
        $.enableScroll();
    }
}

/**
 * Private Function: Show minicart
 */
function showMiniCart() {
    if (isDropdownDisabled() === false) {
        $.disableScroll();
        $('html').addClass('js-minicart-shown');
        $('.js-action__minicart').addClass('show js-popover-shown');
        $('.js-minicart-popover').spinner().start();
        utils.createBackdrop();
        // fire hideMiniCart() when minicart loses focus
        $('.js-action__minicart').loseFocus(hideMiniCart);
    }
}

/**
 * Private Function: Update minicart value
 * @param {Object} data - AJAX response from the server
 */
function setMiniCartContent(data) {
    $('.js-minicart-popover').empty();
    $('.js-minicart-popover').append(data);
    if ($('.minicart-content').hasClass('isempty')) {
        $('.js-minicart-popover').addClass('isempty');
    } else {
        $('.js-minicart-popover').removeClass('isempty');
    }
    // Set Focus on first item inside mini-cart
    $('.js-minicart-popover .product-line-item .line-item-name').first().focus();

    window.dispatchEvent(new Event('minicart:shown:dtm'));
}

/**
 * Private Function: Show minicart
 * @param {number} count - Number of items
 */
function setMiniCartCount(count) {
    $('.minicart-number-of-items').text(count);
    const maxCount = 9;
    const $quantity = $('.minicart .minicart-quantity');
    const newCount = Math.min(parseInt(count, 10), maxCount);

    if (newCount) {
        $quantity.removeClass('isempty').text(newCount);
        $('.minicart .minicart-link').removeClass('isempty');

        $quantity.toggleClass('minicart-quantity--plus', count > maxCount);
    } else {
        // newCount is 0 or NaN
        if (!$quantity.data('show-zero-for-empty')) {
            $quantity.addClass('isempty');
        }

        $quantity.text(0);
        $('.minicart .minicart-link').addClass('isempty');
        $minicartDrawer = null;
    }
}

/**
 * After minicart drawer contents are drawn, bind any click events
 */
function bindDrawerEvents() {
    $('.cart-membership-banner a').on('click', function () {
        // This is a login link in membership banner
        $minicartDrawer.close();
    });
    $('.js-drawer-login').on('click', function () {
        // This is a login link in empty cart
        $minicartDrawer.close();
    });
}

/**
 * Show minicart in drawer.
 * destroyOnClose is true so this will return to the source every time the drawer gets opened.
 * This allows cart contents or inventory to change between one view and the next even in same page session
 */
function showMiniCartDrawer() {
    if (!isDropdownDisabled()) {
        $minicartDrawer = $.drawer({
            src: $('.siteheader').data('minicart-url'),
            size: 'sm',
            class: 'minicart-drawer',
            destroyOnClose: true
        }).on('show.drawer', function () {
            $('.minicart-drawer').spinner().start();
        }).on('shown.drawer', function () {
            $('.minicart-drawer').spinner().stop();
        }).on('contentAsyncLoaded.drawer', function () {
            $('.minicart-content .product-line-item .line-item-name').first().focus();

            window.dispatchEvent(new Event('minicart:shown:dtm'));
            bindDrawerEvents();
        });
    }
}

/**
 * In later iterations this will be refreshing the cart while the drawer is still open
 * There are no flows that get there yet so this is sufficient for now
 */
function refreshMinicartDrawer() {
    showMiniCartDrawer();
}

/**
 * Private Function: Load minicart data from url
 */
function reloadMiniCart() {
    if (useDrawerElement()) {
        refreshMinicartDrawer();
    } else if ($('html').hasClass('js-minicart-shown') === false) {
        const url = $('.siteheader').data('minicart-url');
        showMiniCart();
        $.get(url, function (data) {
            setMiniCartContent(data);
            offsetMiniCart();
        });
    }
}

/**
 * Hide or reload miniCart
 */
function hideReloadMiniCart() {
    if (useDrawerElement()) {
        showMiniCartDrawer();
    } else if ($('html').hasClass('js-minicart-shown')) {
        hideMiniCart();
    } else {
        reloadMiniCart();
    }
}

/**
 * Keyboard Events
 */
function keyboardEvents() {
    keyboardAccessibility('.siteheader__minicart-link',
        {
            13: function () { // enter
                hideReloadMiniCart();
                return true;
            },
            27: function () { // escape
                hideMiniCart();
            },
            38: function () { // up
                hideMiniCart();
                return true;
            },
            40: function () { // down
                reloadMiniCart();
                return true;
            }
        },
        function () {
            return this;
        }
    );

    keyboardAccessibility('.js-minicart-popover a',
        {
            13: function () { // enter
                this.click();
                return true;
            }
        },
        function () {
            return this;
        }
    );
}

/**
 * Intializes cart quantity in the header from client data to avoid
 * multiple remote includes and each load the cart into memory redundantly.
 */
function setMiniCartCountFromDataLayer() {
    const data = window.digitalData;
    let totalUnits = 0;

    if (data && data.cart && data.cart.item) {
        totalUnits = data.cart.item.reduce(function (quantitySum, item) {
            return quantitySum + item.quantity;
        }, 0);
    }

    setMiniCartCount(totalUnits);
    $('.js-siteheader-utility-inner').removeClass('invisible');
}

module.exports = {
    init: function () {
        basketHelper.init();

        keyboardEvents();

        // Global event triggers
        $(document).on('minicart:updatecount', function (event, count) {
            setMiniCartCount(count);
        });

        $(document).on('minicart:show', function () {
            reloadMiniCart();
        });

        $(document).on('minicart:hide', function () {
            hideMiniCart();
        });

        $(document).on('shown.bs.modal', function () {
            hideMiniCart();
        });

        $('.js-action__minicart a').on('click', function (event) {
            if (useDrawerElement()) {
                // The drawer does not have different click behavior on smaller screens
                event.preventDefault();
                showMiniCartDrawer();
            } else if (breakpoints.isHighRes()) {
                // On smaller screens the minicart icon is link-only, no popover
                event.preventDefault();
                hideReloadMiniCart();
            }
        });

        $('.js-action__checkout-minicart a').on('click', function (e) {
            e.preventDefault();
            const $miniCartBtnContainer = $('.js-action__checkout-minicart');
            const url = $miniCartBtnContainer.data('cartUrl');
            const modalMessage = $miniCartBtnContainer.data('redirectMessage');
            const buttonStayText = $miniCartBtnContainer.data('buttonStayText');
            const buttonLeaveText = $miniCartBtnContainer.data('buttonLeaveText');
            $.modal({
                content: modalMessage,
                customClasses: 'leave-checkout-modal',
                showCloseBtn: false,
                modalSizeClass: 'modal-sm',
                buttons: [`<button class="btn btn-block btn-narrow btn-secondary" data-dismiss="modal">${buttonStayText}</button>`, `<a href="${url}" class="btn btn-block btn-primary btn-narrow mb-0 js-btn-leave-checkout">${buttonLeaveText}</a>`]
            });
        });

        setMiniCartCountFromDataLayer();

        $(document).on('dataLayer:userSpecificData:updated', function () {
            setMiniCartCountFromDataLayer();
        });
    }
};
