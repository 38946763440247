'use strict';
const utils = require('../components/utils');
const base = require('./base');
const loyalty = require('./loyalty');
const ImageGallery = require('./imageGallery');
let initialPageLoad = true;

/**
 * Hide Quickview Modal
 */
var hideQuickview = () => {
    if ($('.product-gallery--quickview').swiper) {
        $('.product-gallery--quickview').swiper.destroy();
    }

    $('.quickview-modal').remove(); // this class is added to the modal when it is created, in createQVModal().
    // also remove the backdrop
    $('.modal-backdrop').remove();
    $.enableScroll();
    $('body').removeClass('modal-open').css('padding-right', '');
    $(window).trigger('scroll');
};

var createQVModal = () => {
    let modalMarkup = utils.createModalMarkup('', {
        title: ' ', // Passing a blank / space character creates a blank header-title element in the modal.
        customClasses: 'quickview-modal'
    });
    let $modal = $(modalMarkup).modal();
    $modal
        .on('hidden.bs.modal', () => {
            hideQuickview();
        });
    return $modal;
};

/**
 * Updates the digitalData object.
 * @param {Object} data The data to set in the digitalData object
 */
const updateDTM = (data) => {
    if (!!data && 'dtmLayer' in data && 'digitalData' in window) {
        const propsToUpdate = ['pageInstanceID', 'page', 'user', 'cart'];
        propsToUpdate.forEach((prop) => {
            if (data.dtmLayer[prop]) {
                window.digitalData[prop] = data.dtmLayer[prop];
            }
        });
    }
};

/**
 * Restores the digitalData object.
 * @param {Object} data The data to set in the digitalData object
 */
const restoreDTM = (data) => {
    if (!!data && 'digitalData' in window) {
        const propsToUpdate = ['pageInstanceID', 'page', 'user'];
        propsToUpdate.forEach((prop) => {
            if (data[prop]) {
                window.digitalData[prop] = data[prop];
            }
        });
        delete window.preQuickViewModalDigitalData;
    }
};

/**
 * Initialize prodict image gallery
 */
const initGallery = () => {
    const quickviewGalleryConfig = {
        isQuickview: true,
        galleryContainerSelector: '.product-quickview .primary-images'
    };

    const swiperImageGallery = new ImageGallery(quickviewGalleryConfig);
    swiperImageGallery.init();
};

/**
 * Fetches quickview html from server and launches modal
 * @param {string} quickViewUrl  - Quick view controller endpoint url
 * @param {HTMLElement} $modal - Reference to modal JQuery object
 */
var launchQuickview = (quickViewUrl, $modal) => {
    if (!quickViewUrl) {
        return;
    }

    if (!$modal) {
        $modal = createQVModal(); // eslint-disable-line no-param-reassign
    }

    $modal.modal('show');
    $modal.find('.modal-body').spinner().start();

    let successHandler = (html) => {
        $modal.find('.modal-body').html(html);
        // updated the header
        let $modalContainer = $modal.find('.product-quickview');

        window.dispatchEvent(new Event('quickview:shown:dtm'));

        initGallery();

        $(document).on('product:swiper:update', function () {
            initGallery();
        });

        if ($('.quickview-modal-looks').length) {
            // conditional for Looks We Love specific modal to account for multiple Product url review links for the Product Set
            for (let i = 0; i < $('.set-item').length; i++) {
                let looksPdpUrl = $('.set-item-' + i + ' .looks-pdp-url').attr('href');
                $('.set-item-' + i + ' .qv-ratings').attr('data-link', looksPdpUrl + '?bvOpen=true');
                // check custom preference 'redirectToReviewFromQuickViewLink'
                if ($('.qv-ratings').data('review-link')) {
                    $('.set-item-' + i + ' .qv-ratings').on('click', function (e) {
                        e.preventDefault();
                        looksPdpUrl = utils.setUrlKeyValue(looksPdpUrl, 'bvOpen', true);
                        window.location.href = looksPdpUrl;
                    });
                }
            }
        } else if ($('.qv-ratings').data('review-link')) {
            $('.qv-ratings').on('click', function (e) {
                e.preventDefault();
                let basePDPUrl = $modalContainer.find('.js-qv-pdp-url').attr('href');
                basePDPUrl = utils.setUrlKeyValue(basePDPUrl, 'bvOpen', true);
                window.location.href = basePDPUrl.replace('#void', '');
            });
        }

        /*
        * On click of a LWL Multi View thumbnail alternate, display the corresponding alternate LWL Multi View product
        */
        $('.set-item-multi').on('click', function () {
            let setItem = $('.set-item-' + $(this).data('index'));
            let setItemMulti = $('.set-item-multi-' + $(this).data('index'));
            $('.set-items').find('.set-item.d-block').removeClass('d-block');
            $('.set-items-looks').find('.set-item-multi.active').removeClass('active');
            $(setItem).toggleClass('d-block');
            $(setItemMulti).toggleClass('active');

            // Instantiate new Swiper object on click for alternate products with swiperData config values
            initGallery();
        });

        $('.set-items-wrapper').slick({
            infinite: false,
            slidesToScroll: 1,
            slidesToShow: 5,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToScroll: 1,
                        slidesToShow: 2
                    }
                }
            ]
        });

        /**
         * Necessary to make sure single variants can be added to the cart on load
         */
        var $productContainer = $modal.find('.product-detail');

        if (
            $productContainer.data('pid') === $productContainer.data('master-pid') &&
            base.isEverythingSelected()
        ) {
            var $swatchSelected = $('.product__attributes a.selected').first();
            var selectedAttributeID = $swatchSelected.closest('.js-attribute').data('attr');

            base.attributeSelect($swatchSelected.data('attr-url'), $productContainer, selectedAttributeID);
        }
        base.quickViewSwatchFunctionality();
        base.updateOOSColorVariants($productContainer);
        base.forceOOSNotifyMe();
    };

    // Persisting the digital data before opening the quick view modal, to restore it back once the modal is closed.
    // Digital data is stringified and stored in another variable as direct assignment results in copying by reference because of which change in original variable results in change of the target one.
    window.preQuickViewModalDigitalData = JSON.stringify(window.digitalData);
    $.ajax({
        url: quickViewUrl,
        method: 'GET',
        dataType: 'json',
        success: (data) => {
            if (!$modal[0].parentElement) {
                // success was called before the modal is shown.
                $modal.on('shown.bs.modal', () => {
                    updateDTM(data);
                    // Copying the pageHier attribute from the source page of quick view modal.
                    var preQuickViewModalDigitalData = JSON.parse(window.preQuickViewModalDigitalData);
                    if (preQuickViewModalDigitalData && preQuickViewModalDigitalData.page && preQuickViewModalDigitalData.page.pageInfo) {
                        window.digitalData.page.pageInfo.pageHier = preQuickViewModalDigitalData.page.pageInfo.pageHier + ':QuickView';
                    }
                    successHandler(data.renderedTemplate);
                });
            } else {
                updateDTM(data);
                // Copying the pageHier attribute from the source page of quick view modal.
                var preQuickViewModalDigitalData = JSON.parse(window.preQuickViewModalDigitalData);
                if (preQuickViewModalDigitalData && preQuickViewModalDigitalData.page && preQuickViewModalDigitalData.page.pageInfo) {
                    window.digitalData.page.pageInfo.pageHier = preQuickViewModalDigitalData.page.pageInfo.pageHier + ':QuickView';
                }
                successHandler(data.renderedTemplate);
            }
            $modal.on('hidden.bs.modal', () => {
                window.preQuickViewModalDigitalData = JSON.parse(window.preQuickViewModalDigitalData);
                // Restores the digital data back to what it was before the quick view modal.
                restoreDTM(window.preQuickViewModalDigitalData);
            });
            // updates forecasted loyalty points
            loyalty.init();
        },
        complete: () => {
            $.spinner().stop();
            $.disableScroll();
        },
        error: () => {
            hideQuickview();
        }
    });
};

/**
 * Start spinned inside QV
 */
const startSpinner = () => {
    const $modalBody = $('.quickview-modal .modal-body');
    if ($modalBody.length) {
        $modalBody.spinner().start();
    }
};

/**
 * Sets Out of Stock status text based on the selectable/unselectable state of the attribute
 * @param {HTMLElement} $swatchEntered  - targeted swatch on click/hover
 * @param {string} selectedValue - selected text value
 * @param {HTMLElement} attributeLabelValue - selected text element
 */
const setSelectedValue = ($swatchEntered, selectedValue, attributeLabelValue) => {
    const outOfStockText = $swatchEntered.closest('[data-attr-oos-text]').data('attr-oos-text');

    if (selectedValue !== undefined) {
        if ($swatchEntered.hasClass('unselectable')) {
            attributeLabelValue.text(selectedValue + ' ' + outOfStockText).addClass('text-danger');
        } else {
            attributeLabelValue.text(selectedValue).removeClass('text-danger');
        }
    }
};

/**
 * QuickView Custom events on Attribute click and hover
 */
const onSelectAttribute = () => {
    // Color Attribute click event to display selected/selectable status
    $('.js-product-content').on('attribute:click', '.js-swatch', function (e) {
        const $swatchClicked = $(e.currentTarget);
        const attrType = $swatchClicked.closest('.js-attribute').data('attr');
        const attributeLabelValue = $swatchClicked.closest('.js-attribute').find('.js-attr-label-value');
        let selectedValue;

        if (attrType === 'color') {
            selectedValue = $swatchClicked.find('.color-value').attr('title');
        } else {
            selectedValue = $swatchClicked.data('attr-hover');
        }

        setSelectedValue($swatchClicked, selectedValue, attributeLabelValue);
    });

    // Size/Dimension Attribute Select event to get the new attribute selected/selectable status
    $(document).on('attribute:select', '.js-product-content .js-size-swatch, .js-product-content .js-dimension-swatch', function (e, $attrValue, attrValue) {
        const outOfStockText = $attrValue.closest('[data-attr-oos-text]').data('attr-oos-text');
        const attributeLabelValue = $attrValue.closest('.js-attribute').find('.js-attr-label-value');
        attributeLabelValue.text($attrValue.data('attr-hover'));
        if (!attrValue.selectable) {
            attributeLabelValue.addClass('text-danger').text($attrValue.data('attr-hover') + ' ' + outOfStockText);
        } else {
            attributeLabelValue.removeClass('text-danger').text($attrValue.data('attr-hover'));
        }
    });
};

/**
 * updates QuickViewView Details url if Variation atttibute selected is Out of Stock
 * @param {Object} response - response data object from ajax
 */
const updateViewDetailsURL = (response) => {
    if (response && response.data) {
        const responseDataProduct = response.data.product;
        const selectedProductUrl = responseDataProduct && responseDataProduct.selectedProductUrl ? responseDataProduct.selectedProductUrl : '';
        const masterId = responseDataProduct && responseDataProduct.masterId ? responseDataProduct.masterId : '';
        let OOSSelectedProductUrl;
        const $pdpLinks = $('.product-quickview .js-qv-pdp-url');

        // if the selected variation attribute is Out of Stock, remove the size parameter from the url
        if (responseDataProduct.OOSSelectedAttributes) {
            const OOSSelectedAttributeSize = responseDataProduct.OOSSelectedAttributes && responseDataProduct.OOSSelectedAttributes.size ? responseDataProduct.OOSSelectedAttributes.size : '';
            const urlSizeParameter = '&dwvar_' + masterId + '_size=' + OOSSelectedAttributeSize;
            if (selectedProductUrl.indexOf(urlSizeParameter)) {
                OOSSelectedProductUrl = selectedProductUrl.replace(urlSizeParameter, '');
            }
            $pdpLinks.attr('href', OOSSelectedProductUrl);
        } else {
            $pdpLinks.attr('href', responseDataProduct.selectedProductUrl);
        }
    }
};

module.exports = {
    initQuickview: () => {
        $('body')
            .off('.qv', '.js-quickview')
            .on('click.qv touchend.qv', '.js-quickview', (e) => {
                // Don't initiate qv when product tile image is clicked in desktop breakpoint
                let $this = $(e.currentTarget);
                let link = $this.data('link') || $this.attr('href');
                let qs = utils.queryStringToObject(link);
                if (!qs.pid) {
                    return;
                }
                e.preventDefault();

                let $modal = createQVModal();

                launchQuickview(link, $modal);
            });
    },
    launchEditProduct: (editProductUrl) => {
        if (editProductUrl) {
            let $modal = createQVModal();
            launchQuickview(editProductUrl, $modal);
        }
    },
    hideQuickview: hideQuickview,
    startSpinner: startSpinner,
    beforeAddToCart: () => {
        $('body').on('product:beforeAddToCart', function (data) {
            $(data).closest('.modal-body').spinner().start();
        });
    },
    afterAddToCart: () => {
        $('body').on('product:afterAddToCart', () => {
            if ($('.cart-container').length) {
                // we're on the cart page.
                $.spinner().start();
                location.reload();
            } else if ($('.quickview-modal .product-set').length) {
                $.spinner().stop();
            } else {
                hideQuickview();
            }
        });
    },
    updateAttribute: () => {
        $('body').on('product:afterAttributeSelect', (e, response) => {
            if ($('.modal.show .product-quickview>.bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container)
                    .find('.product-id')
                    .data('pid', response.data.product.id)
                    .text(response.data.product.materialStyleNumber);
            } else if ($('.set-items').length) {
                $(response.container)
                    .find('.product-id')
                    .data('pid', response.data.product.id)
                    .text(response.data.product.materialStyleNumber);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
                updateViewDetailsURL(response);
            }
        });
    },
    updateAddToCart: () => {
        $('body').on('product:updateAddToCart', (e, response) => {
            // update global add to cart (single products, bundles)
            var $dialog = $(response.$productContainer)
                .closest('.quickview-modal');

            // update local add to cart (for sets)
            if (response.product.readyToOrder && !response.product.available) {
                $('.js-product__message--partners', $dialog).show();
            }

            if (base.isEverythingSelected()) {
                $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').attr('disabled', false);
            }

            // Upon initial load, products are not ready for order because a size isn't selected (unless O/S or only one size in stock).
            // At that time, the button to add to cart should be enabled until the customer clicks it.
            // After that, the button should disabled and the customer should follow displayed messaging regarding making a selection.
            const disableAddToCart = !response.product.available || (!response.product.readyToOrder && !initialPageLoad);
            $('button.add-to-cart, button.update-cart-product-global', response.$productContainer)
                .attr('disabled', disableAddToCart)
                .text(response.product.buttonText);

            if (disableAddToCart) {
                initialPageLoad = false;
            }

            let enable = $('.product-availability', $dialog).toArray().every(function (item) {
                return $(item).data('available') && $(item).data('ready-to-order');
            });

            if (enable) {
                $('.js-product__message--partners', $dialog).hide();
            }
        });
    },
    updateAvailability: () => {
        $('body').on('product:updateAvailability', (e, response) => {
            var $dialog = $(response.$productContainer)
                .closest('.quickview-modal');

            if ($dialog.length) {
                $('div.availability', $dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available);

                // Might need to preface with div.availability
                const $availabilityMsgContainer = $('.availability-msg', $dialog).toggleClass('d-none', !response.message);

                if (response.availabilityMessages && response.availabilityMessages.length) {
                    response.availabilityMessages.forEach(message => {
                        $availabilityMsgContainer.alert({
                            content: message.content,
                            theme: message.theme,
                            dismissible: false,
                            autoClose: false
                        });
                    });
                }

                $('.product__message--velocity', $dialog)
                    .empty()
                    .html(response.velocityMessage);

                var variationAttr = response.$productContainer.prevObject.closest('.js-attribute').data('attr').toLowerCase();
                $('.selectswatch-' + variationAttr + '-msg', response.$productContainer).removeClass('d-inline').addClass('d-none');

                $('.outofstock-combination-msg', $dialog);

                if (response.product.notifyme && !response.product.isMaster) {
                    $('.product__add-to-cart', $dialog).hide();
                    $('.product__notify-me', $dialog).show();
                    $('.product__message--velocity', $dialog).addClass('product__message--velocity--notifyme').show();
                    $('input[name=variantPID]', $dialog).val(response.product.id);
                } else {
                    $('.product__add-to-cart', $dialog).show();
                    $('.product__notify-me', $dialog).hide();

                    // Display inventory threshold message
                    if (response.velocityMessage !== '') {
                        $('.product__message--velocity', response.$productContainer).removeClass('product__message--velocity--notifyme').show();
                    }
                }

                if ($('.global-availability', $dialog).length) {
                    // This is used for a Product Bundles and Product Sets. Checks all the
                    // sub-products for Available and Ready. If all selected variants are
                    // selected, then update the global-availability data.
                    var allAvailable = $('.product-availability', $dialog).toArray()
                        .every(function (item) { return $(item).data('available'); });

                    var allReady = $('.product-availability', $dialog).toArray()
                        .every(function (item) { return $(item).data('ready-to-order'); });

                    $('.global-availability', $dialog)
                        .data('ready-to-order', allReady)
                        .data('available', allAvailable);

                    $('.global-availability .availability-msg', $dialog).empty(); // response.resources.info_selectforstock

                    if (response.availabilityMessages && response.availabilityMessages.length) {
                        response.availabilityMessages.forEach(message => {
                            $availabilityMsgContainer.alert({
                                content: message.content,
                                theme: message.theme,
                                dismissible: false,
                                autoClose: false
                            });
                        });
                    }
                }

                $('body').trigger('product:checkOutOfStock', response);
            }
        });
    },
    closeQuickview: () => {
        $('.quickview-modal').on('click', (e) => {
            if (e.target !== e.currentTarget) {
                return;
            }
            hideQuickview();
        });
    },
    closeQuickviewButton: () => {
        $('.js-close-quickView').on('click', () => {
            hideQuickview();
        });
    },
    onSelectAttribute: onSelectAttribute,
    updateViewDetailsURL: updateViewDetailsURL
};
