/* global clientData */
'use strict';

/**
 * Product Base Module
 */

var utils = require('../components/utils');
var onModel = require('../product/onModelDescription');
var Badge = require('../product/badge.js');
var Overview = require('../product/overview.js');

/*
 * All needed methods from app_storefront_base/cartridge/client/default/js/product/base.js have been overridden here
 */

var base = require('base/product/base');
var breakpoints = require('../components/breakpoints');

const $mainPDPGallery = $('.product-gallery--main');
const oosData = {};

/**
 * method to identify if BISN is enabled at variant level
 * @param {Object} attrValue  color swatch value
 * @param {Object} $productObject Product object
 * @returns {boolean} true if BISN is checked at variant level, else false
 */
function isNotifyMeEnabled(attrValue, $productObject) {
    var enableNotifyMe;
    var varKeys = Object.keys($productObject.variantData);
    varKeys.forEach(function (key) {
        if ($productObject.variantData[key].color === attrValue.id) {
            enableNotifyMe = $productObject.variantData[key].bisnflag;
            return false;
        }
        return true;
    });
    return enableNotifyMe;
}
/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('.quickview-modal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').data('pid');
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {Object} $productObject product object
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {string} topLevelAttribute - Either 'color' or 'sizeType'
 */
function processSwatchValues(attr, $productObject, $productContainer, topLevelAttribute) {
    attr.values.forEach(function (attrValue) {
        const $swatchSpan = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');
        const $swatchAnchor = $swatchSpan.parent();
        const swatchOutOfStockText = $swatchAnchor.closest('.js-color-attribute').data('attr-oos-text');

        // Disable if not selectable
        $swatchSpan.removeClass('selected selectable unselectable');
        $swatchAnchor.removeClass('selected selectable unselectable'); // This is only for visual display
        $swatchAnchor.data('attr-oos', '');

        let url;
        if (attr.topLevelAttribute) { // Color as top-level
            /**
            * The top-level attribute (either color or sizeType) should always be clickable:
            *
            * For example, if you selected Standard/Black/M, normally you would not be
            * able to switch from Standard to Big, because Black/M is not a value combination
            * for Big.
            **/
            url = attrValue.url;
            if (url === undefined) {
                // This would be very rare
                $swatchAnchor.data('attr-url', '');
                $swatchAnchor.addClass('unselectable');
                $swatchSpan.addClass('unselectable');
            } else {
                // Top level is always selectable... Unless the URL above is empty.
                $swatchAnchor.data('attr-url', url);
                $swatchAnchor.addClass('selectable');
                $swatchSpan.addClass('selectable');
            }
        } else { // Color when SizeType is top-level.
            url = attrValue.url;
            if (url === undefined) {
                // This would be very rare
                $swatchAnchor.data('attr-url', '');
                $swatchAnchor.addClass('unselectable');
                $swatchSpan.addClass('unselectable');
            } else {
                if (topLevelAttribute === 'sizeType' && attrValue.url === undefined) { // eslint-disable-line
                    // If there is a SizeType dimension, this will mess up stuff...
                    $swatchAnchor.data('attr-url', '');
                    $swatchAnchor.addClass('unselectable');
                    $swatchSpan.addClass('unselectable');
                } else {
                    $swatchAnchor.data('attr-url', url);
                    $swatchAnchor.addClass('selectable');
                    $swatchSpan.addClass('selectable');
                }
            }
        }
        const enableNotifyMe = isNotifyMeEnabled(attrValue, $productObject);

        // Reset aria-label attribute for each color option
        $swatchAnchor.attr('aria-label', attrValue.displayValue);
        if (attrValue.selected) {
            $swatchAnchor.addClass('selected');
            $swatchAnchor.attr('aria-label', attrValue.displayValue + ' ' + $swatchAnchor.data('selected-label'));
            $swatchAnchor.trigger('blur').on('focus', (e) => {
                e.preventDefault();
                e.target.focus({ preventScroll: true });
            });
            $swatchSpan.addClass('selected');
            $swatchAnchor.trigger('attribute:select', [$swatchAnchor, attrValue]);
        }

        if (attrValue.masterSelectable === false && enableNotifyMe !== null && !enableNotifyMe) {
            $swatchAnchor.addClass('not-orderable');
            $swatchSpan.addClass('not-orderable');
            if ($swatchAnchor.hasClass('unselectable')) {
                $swatchAnchor.removeClass('unselectable');
                $swatchSpan.removeClass('unselectable');
            }
        } else if (attrValue.masterSelectable === false && enableNotifyMe) {
            if (!$swatchAnchor.hasClass('unselectable')) {
                $swatchAnchor.addClass('unselectable');
                $swatchSpan.addClass('unselectable');
            }
        }

        if (attrValue.pdpUrl === undefined) {
            $swatchAnchor.removeAttr('href');
        } else {
            $swatchAnchor.attr('href', attrValue.pdpUrl);
        }

        if ($swatchAnchor.hasClass('unselectable')) {
            $swatchAnchor.attr('data-attr-oos', swatchOutOfStockText); // sets the data attribute to the out of stock text
        } else {
            $swatchAnchor.attr('data-attr-oos', '');
        }

        /*
        window.console.log('---',
            'ID:', attr.id,
            'Value:', attrValue.value,
            'Selected:', attrValue.selected,
            'Selectable:', attrValue.selectable, '\n',
            'url:', (attrValue.url && attrValue.url.split('?').length ? attrValue.url.split('?')[1] : attrValue.url), '\n'
        );
        */
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {string} topLevelAttribute - Either 'color' or 'sizeType'
 */
function processNonSwatchValues(attr, $productContainer, topLevelAttribute) { // eslint-disable-line
    let count = 0;
    let selectedCount = 0;
    attr.values.forEach(function (attributeValue) {
        if (attributeValue.selectable) {
            count++;
        }
        if (attributeValue.selected) {
            selectedCount++;
        }
    });
    attr.values.forEach(function (attrValue) {
        const $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');
        const attrOutOfStockText = $attrValue.closest('.js-size-attribute, .js-dimension-attribute').data('attr-oos-text');

        // Reset Everything
        $attrValue.removeClass('selected selectable unselectable');
        $attrValue.data('attr-oos', '');
        if (selectedCount === 0) {
            $attrValue.closest('.js-attribute').find('.js-attr-label-value').removeClass('text-danger').text('');  // reset attribute label
        }

        let url;
        if (attr.topLevelAttribute) { // SizeType
            /**
            * The top-level attribute (either color or sizeType) should always be clickable:
            *
            * For example, if you selected Standard/Black/M, normally you would not be
            * able to switch from Standard to Big, because Black/M is not a value combination
            * for Big.
            **/
            url = attrValue.url;
            if (url === undefined) {
                // This would be very rare
                $attrValue.data('attr-url', '');
                $attrValue.addClass('unselectable');
            } else {
                // Top level is always selectable... Unless the URL above is empty.
                $attrValue.data('attr-url', url);
                $attrValue.addClass('selectable');
            }
        } else { // Size or Dimension
            url = attrValue.url;
            if (url === undefined) {
                // This would be very rare
                $attrValue.data('attr-url', '');
                $attrValue.addClass('unselectable');
            } else {
                $attrValue.data('attr-url', url);

                if (attrValue.selectable) {
                    $attrValue.addClass('selectable');
                } else {
                    $attrValue.addClass('unselectable');
                }
            }
        }

        // Reset aria-label attribute
        $attrValue.attr('aria-label', $attrValue.data('attr-hover'));
        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.attr('aria-label', $attrValue.data('attr-hover') + ' ' + $attrValue.data('selected-label'));
            $attrValue.trigger('blur').on('focus', (e) => {
                e.preventDefault();
                e.target.focus({ preventScroll: true });
            });
            $attrValue.trigger('attribute:select', [$attrValue, attrValue]);
        }

        if (attr.attributeId && attr.attributeId.toLowerCase() === 'dimension') {
            if (attrValue.selectable) {
                if (count === 1 && attrValue.selected) {
                    $attrValue.addClass('selected');
                } else {
                    $attrValue.addClass('selectable');
                }
            } else {
                $attrValue.addClass('unselectable');
            }
        }

        if (attrValue.masterSelectable === false) {
            $attrValue.addClass('unselectable');
        }

        if (attrValue.pdpUrl === undefined) {
            $attrValue.removeAttr('href');
        } else {
            $attrValue.attr('href', attrValue.pdpUrl);
        }

        if ($attrValue.hasClass('unselectable')) {
            $attrValue.attr('data-attr-oos', attrOutOfStockText); // sets the HTML data attribute to the out of stock text
        } else {
            $attrValue.attr('data-attr-oos', '');
        }

        /*
        window.
        ('---',
            'ID:', attr.id,
            'Value:', attrValue.value,
            'Selected:', attrValue.selected,
            'Selectable:', attrValue.selectable, '\n',
            'url:', (attrValue.url && attrValue.url.split('?').length ? attrValue.url.split('?')[1] : attrValue.url), '\n'
        );
        */
    });
}

/**
 * Color swatches can be broken up into price groups. If all the swatches are hidden, also hide the price label.
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function hideColorPriceGroups($productContainer) {
    $('.priceGroup', $productContainer).each(function () {
        var groupVisible = false;
        $(this).find('.color-value').each(function () {
            if ($(this).hasClass('selectable')
                && $(this).hasClass('not-orderable') === false
                && $(this).hasClass('invalid') === false
            ) {
                groupVisible = true;
            }
        });

        if (groupVisible) {
            $(this).show();
        } else {
            $(this).hide();
        }
    });
}

/**
 * Pass in an array of SKUs, the variantData array, and and Attribute ID
 * Returns an array of unique Attribute Values from the SKUs
 *
 * @param {Object} variantData - Indexed by SKU. See models/product/decorators/variantMatrix.js
 * @param {array} skuArray - Array of skus
 * @param {string} attributeID - Attribute ID
 * @return {array} - Array of unique attribute values
 */
function getSkuValues(variantData, skuArray, attributeID) {
    var arr = [];
    for (var i = 0; i < skuArray.length; i++) {
        var sku = skuArray[i];
        if (variantData[sku] !== undefined) {
            var attributeValue = variantData[sku][attributeID];
            if (arr.indexOf(attributeValue) === -1) {
                arr.push(attributeValue);
            }
        }
    }
    return arr; // These are all the valid values
}

/**
 * For products with 3 dimensions of attributes, we must hide/show swatch combinations
 * that are not valid possibilities. For example, a pant sizes of SizeType "Big" and a
 * Size of "30/30". Big pants are not available in this size...
 *
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {array} attrs - Attributes from the AJAX productObject.variationAttributes result
 * @param {string} topLevelAttribute - sizeType, color or empty
 * @param {array} allAttributes - Possible values are sizeType, color, size, dimension
 * @param {Object} variantData - Indexed by SKU. See models/product/decorators/variantMatrix.js
 * @param {Object} variantMatrix - Indexed by Attribute. See models/product/decorators/variantMatrix.js
 */
function hideShowValidSwatches($productContainer, attrs, topLevelAttribute, allAttributes, variantData, variantMatrix) {
    if (topLevelAttribute === '') {
        return;
    }

    var hasSizeType = allAttributes.indexOf('sizeType') !== -1;
    var hasColor = allAttributes.indexOf('color') !== -1;
    var hasSize = allAttributes.indexOf('size') !== -1;
    var hasDimension = allAttributes.indexOf('dimension') !== -1;

    // The functionality below only applies in these two scenarios:
    // 1. A product which SizeType + Color + Size
    // 2. A product which has Color + Size + Dimension
    if (!(hasSizeType || hasDimension)) {
        return;
    }

    // Get all the currently selected attributes values:
    var selectedAttributes = {};
    attrs.forEach(function (attr) {
        attr.values.forEach(function (attrValue) {
            if (attrValue.selected) {
                selectedAttributes[attr.id] = attrValue.value;
            }
        });
    });

    var skuArray;
    if (topLevelAttribute === 'sizeType' && hasSizeType) {
        $productContainer.find('.js-attribute-size .js-attribute-value').removeClass('invalid');
        $productContainer.find('.js-attribute-color .js-attribute-value').removeClass('invalid');

        // There should always be a SizeType selected, otherwise we
        // cannot determine which color and size swatches are valid.
        var selectedSizeType = selectedAttributes['sizeType']; // eslint-disable-line dot-notation
        if (selectedSizeType === undefined) {
            return;
        }

        // Array of skus which have the selected SizeType.
        skuArray = variantMatrix['sizeType'][selectedSizeType]; // eslint-disable-line dot-notation

        // Based on the selected SizeType, show/hide "valid" Size swatches.
        if (hasSize) {
            var validSizes = getSkuValues(variantData, skuArray, 'size'); // Array of unique "Size" values in sku array.

            // Hide all skus with invalid values
            if (validSizes.length) {
                $productContainer.find('.js-attribute-size .js-attribute-value').each(function () {
                    var val = $(this).data('attr-value');
                    if (val !== undefined && validSizes.indexOf(String(val)) === -1) {
                        // Value of swatch does not appear in array. Hide swatch.
                        $(this).addClass('invalid');
                    }
                });
            }
        }

        // Based on the selected SizeType, show/hide "valid" Color swatches.
        if (hasColor) {
            var validColors = getSkuValues(variantData, skuArray, 'color'); // Array of unique "Color" values in sku array.

            if (validColors.length) {
                $productContainer.find('.js-attribute-color .js-attribute-value').each(function () {
                    var val = $(this).data('attr-value');
                    if (val !== undefined && validColors.indexOf(String(val)) === -1) {
                        // Value of swatch does not appear in array. Hide swatch.
                        $(this).addClass('invalid');
                    }
                });
            }
        }
    } else if (topLevelAttribute === 'color' && hasDimension) {
        $productContainer.find('.js-attribute-dimension .js-attribute-value').removeClass('invalid');

        // Dimension is an attribute of the selected Size and Color.
        var selectedSize = selectedAttributes['size']; // eslint-disable-line dot-notation
        if (selectedSize === undefined) {
            return;
        }

        var skuColorArray = [];
        var skuSizeArray = [];
        if (hasColor) {
            var selectedColor = selectedAttributes['color']; // eslint-disable-line dot-notation
            if (selectedColor !== undefined) {
                skuColorArray = variantMatrix['color'][selectedColor]; // eslint-disable-line dot-notation
            }
        }

        // Based on the selected Size, show/hide "valid" Size swatches.
        if (hasSize) {
            // Array of skus which have the selected SizeType.
            skuSizeArray = variantMatrix['size'][selectedSize]; // eslint-disable-line dot-notation

            // Note: Both Color and Size must be selected to determine which lengths are "valid" options.
            if (skuSizeArray.length && skuColorArray.length) {
                // If both Size and Color are selected, determine which skus
                // appear in both arrays via intersection.
                skuArray = utils.arrayIntersection(skuSizeArray, skuColorArray);
            } else {
                skuArray = skuSizeArray;
            }

            var validDimensions = getSkuValues(variantData, skuArray, 'dimension'); // Array of unique "dimension" values in sku array.

            // Hide all skus with invalid values
            if (validDimensions.length) {
                $productContainer.find('.js-attribute-dimension .js-attribute-value').each(function () {
                    var val = $(this).data('attr-value');
                    if (val !== undefined && validDimensions.indexOf(String(val)) === -1) {
                        // Value of swatch does not appear in array. Hide swatch.
                        $(this).addClass('invalid');
                    }
                });
            }
        }
    }
}

/**
 * Get selected size/dimension swatch
 * @param {string} variantID Variant ID to locate in the productCache data
 * @param {Object} swatchArrays Contains arrays of variants, organized by size/dim
 * @param {Array} swatchKeys Array containing swatch key values (eg. 'S', 'M', 'L', etc.)
 * @return {string} Size or dimension that is currently selected
 */
function getSelectedSwatchValue(variantID, swatchArrays, swatchKeys) {
    var result;
    for (let i = 0; i < swatchKeys.length; i++) {
        if (swatchArrays[swatchKeys[i]].includes(variantID)) {
            result = swatchKeys[i];
            break;
        }
    }
    return result;
}

/**
 * Adds 'unselectable' class to color swatches
 * @param {Object} colorVariantArrays Object containing variantID arrays, grouped by color ID
 * @param {Array} colorVariantKeys Array of color ID indices
 * @param {string} variantID Target variant ID
 */
function markOOSColorVariants(colorVariantArrays, colorVariantKeys, variantID) {
    colorVariantKeys.forEach(colorVarKey => {
        if (colorVariantArrays[colorVarKey].includes(variantID)) {
            // Find the matching color swatch span and mark the parent anchor element as 'unselectable'
            $('.js-attribute-value[data-attr-value="' + colorVarKey + '"]')
                .closest('.js-color-swatch')
                .removeClass('selectable')
                .addClass('unselectable');
        }
    });
}

/**
 *
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateOOSColorVariants($productContainer) {
    const masterID = String($productContainer.data('master-pid'));
    const variantID = String($productContainer.data('pid'));

    // Exit function if productCache prerequisites are not met
    if (!window.productCache || !masterID || !variantID ||
        !window.productCache[masterID] ||
        !window.productCache[masterID].variantMatrix ||
        !window.productCache[masterID].variantMatrix.inventory) {
        return;
    }

    if (JSON.stringify(oosData) === '{}') {
        oosData.colorVariantArrays = window.productCache[masterID].variantMatrix.color || {};
        oosData.colorVariantKeys = Object.keys(oosData.colorVariantArrays);
        oosData.sizeVariantArrays = window.productCache[masterID].variantMatrix.size || {};
        oosData.sizeVariantKeys = Object.keys(oosData.sizeVariantArrays);
        oosData.dimVariantArrays = window.productCache[masterID].variantMatrix.dimension || {};
        oosData.dimVariantKeys = Object.keys(oosData.dimVariantArrays);
    }

    const oosVariants = window.productCache[masterID].variantMatrix.inventory[0];
    if (oosVariants) {
        let selectedSize;
        let selectedDim;

        selectedSize = oosData.sizeVariantArrays ? getSelectedSwatchValue(variantID, oosData.sizeVariantArrays, oosData.sizeVariantKeys) : null;
        selectedDim = oosData.dimVariantArrays ? getSelectedSwatchValue(variantID, oosData.dimVariantArrays, oosData.dimVariantKeys) : null;

        if (selectedSize) {
            if (selectedDim) {
                // Locate variantID that may exist in intersection of size & dimension arrays
                // and identify which colors are out of stock for this size & dimension combination
                oosData.dimVariantArrays[selectedDim].forEach(variant => {
                    if (oosVariants.includes(variant) && oosData.sizeVariantArrays[selectedSize].includes(variant)) {
                        markOOSColorVariants(oosData.colorVariantArrays, oosData.colorVariantKeys, variant);
                    }
                });
            } else {
                // Loop through 'size' variants and identify which colors are out of stock for this size
                oosData.sizeVariantArrays[selectedSize].forEach(variant => {
                    if (oosVariants.includes(variant)) {
                        markOOSColorVariants(oosData.colorVariantArrays, oosData.colorVariantKeys, variant);
                    }
                });
            }
        }
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} productObject - Product Data
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAttrs(productObject, $productContainer) {
    var attrs = productObject.variationAttributes;

    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    // Find attribute with topLevelAttribute == true
    var topLevelArray = attrs.filter(function (attr) {
        return attr.topLevelAttribute;
    });

    // Possible values are: sizeType, color or empty.
    var topLevelAttribute = topLevelArray.length ? topLevelArray[0].id : '';

    // Determine all available attributes: sizeType, color, size, dimension
    var allAttributes = [];
    attrs.forEach(function (attr) {
        allAttributes.push(attr.id);
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, productObject, $productContainer, topLevelAttribute);
        } else {
            processNonSwatchValues(attr, $productContainer, topLevelAttribute);
        }
    });

    // variantData and variantMatrix values are set in productDetails.isml and added to the
    // window object. Values are indexed by the master PID so multiple Quickview windows
    // are possible without overwriting these values.
    var pid = productObject.masterId;
    if (window.productCache && window.productCache[pid]) {
        var variantData = window.productCache[pid].variantData;
        var variantMatrix = window.productCache[pid].variantMatrix;

        hideShowValidSwatches($productContainer, attrs, topLevelAttribute, allAttributes, variantData, variantMatrix);
    }

    hideColorPriceGroups($productContainer);
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 *
 * @param {Object} selectedAttributeID - attribute being updated
 */
function updateAvailability(response, $productContainer, selectedAttributeID) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    // this boolean is to hide the "In Stock" message on PDP page
    var instockBool = response.product.availability.instock;
    var velocityValue = '';
    var velocityMessages = response.product.velocityMessage.messages;

    // Adding the condition if price is null then "Availability message" should be disabled
    if (!instockBool && response.product.readyToOrder && response.product.price.sales && response.product.price.sales.value && availabilityMessages.length) {
        availabilityValue += '<div class="availability-msg__container alert__content">';
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<div class="message">' + message.content + '</div>';
        });
        availabilityValue += '</div>';
    }

    // Reset Notify Me form and messages
    $('.notifyMeFormMessage').hide();
    $('.js-notifyMeFormContainer').hide();

    if (velocityMessages.length) {
        velocityValue += '<div class="velocity-msg__container">';
        velocityMessages.forEach(function (message) {
            velocityValue += $('<div/>', {
                class: message.cssClass ? message.cssClass : '',
                text: message.text
            })[0].outerHTML;
        });
        velocityValue += '</div>';
        // Reveal "Notify Me" button and update variant ID
        $('.notify-me.btn').show().data('cta', 'showForm').data('pid', response.product.id);
    } else {
        // hide notify me messages and button
        $('.product__message--velocity').hide();
        $('.notify-me.btn').hide();
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        selectedAttributeID: selectedAttributeID,
        message: availabilityValue,
        availabilityMessages,
        resources: response.resources,
        velocityMessage: velocityValue
    });
}

/**
 * Make "notify me" status visible on variant swatches
 */
function setVariantNotifyMeStatus() {
    const variantMaster = $('.product-detail').data('master-pid');
    const colorSwatchID = $('.js-attribute-value.selected').data('attr-value');

    // In checkout, this object does not exist, causing an error to be thrown in the console.
    if (!window.productCache) {
        return;
    }

    const colorSwatchVariantArray = window.productCache[variantMaster].variantMatrix.color[colorSwatchID];

    // Lookup variant sizes
    colorSwatchVariantArray.forEach(function (variant) {
        const variantSize = window.productCache[variantMaster].variantData[variant].size;
        const variantNotifyMe = window.productCache[variantMaster].variantData[variant].bisnflag;
        const $swatch = $('.js-size-swatch[data-attr-hover="' + variantSize + '"]');

        // Flag swatches to indicate notify me status (see forceOOSNotifyMe)
        $swatch.toggleClass('js-notifyme-swatch', !!variantNotifyMe);
    });
}

/**
 * Force notify me message to display if all variants are OOS
 */
function forceOOSNotifyMe() {
    const $notifymeVariant = $('.js-notifyme-swatch.selected');
    const allOOS = $notifymeVariant.length &&
                   $('.js-size-swatch.unselectable').length === $('.js-size-swatch').length;

    if (allOOS && $('.js-notifyMeFormContainer').is(':empty')) {
        const selectedAttributeID = $notifymeVariant.closest('.js-attribute').data('attr');
        const velocityMessage = $('.selectswatch-size-msg').text();
        const pid = $('.product-detail').data('pid');
        let $productContainer = $notifymeVariant.closest('.set-item');

        if (!$productContainer.length) {
            $productContainer = $notifymeVariant.closest('.product-detail');
        }

        $(window).trigger('product:triggerNotifyMe', { hideForm: true });

        $($productContainer).trigger('product:updateAvailability', {
            product: {
                id: pid,
                notifyme: true
            },
            $productContainer: $productContainer,
            selectedAttributeID: selectedAttributeID,
            velocityMessage: velocityMessage
        });
    }
}

/**
 * Generates html for promotions section
 *
 * @param {array} promotions - list of promotions
 * @return {string} - Compiled HTML
 */
function getPromotionsHtml(promotions) {
    if (!promotions) {
        return '';
    }

    var html = '';

    promotions.forEach(function (promotion) {
        promotion.callOutMessageDisplayPages.forEach(function (displayPage) {
            if (displayPage.value === 'PDP') {
                html += '<div class="callout" title="' + promotion.details + '">' + promotion.calloutMsg +
            '</div>';
            }
        });
    });

    return html;
}

/**
 * Change product primary images when user hovers on color swatches
 * @param {Object} images - images to be updated
 * @param {Object} $productContainer - Product Details container reference
 */
function onColorVariantHover(images, $productContainer) {
    // Update primary images
    if (images) {
        const $imageGalleries = $productContainer.find('.js-product-gallery, .js-product-gallery-thumb');
        const retinaEnabled = window.resources.images.retinaEnabled || false;
        const hiresImgUrlParams = window.resources.images.hiresImgUrlParams;
        const pdpImgUrlParams = window.resources.images.pdpProductImgAppend;
        const badgeData = $productContainer.data('badge');
        const pdpTechBadgeData = $productContainer.data('pdp-tech-badge');
        const isQuickView = $productContainer.hasClass('product-quickview');
        const enableStylitics = window.resources.stylitics && window.resources.stylitics.enabled;
        const MAX_QV_IMG_COUNT = 8; // Limit number of images on quickview

        $imageGalleries.each(function () {
            const $imageGallery = $(this);
            const $swiperWrapper = $imageGallery.find('.swiper-wrapper');
            const swiperSlideStyle = $swiperWrapper.find('.swiper-slide').first().attr('style') || '';
            const isThumbnail = $imageGallery.hasClass('js-product-gallery-thumb');
            let imageAltIdx = 1;

            $swiperWrapper.empty();

            for (let idx = 0; idx < images.length; idx++) {
                let imageData = images[idx];
                if (isQuickView && idx === MAX_QV_IMG_COUNT) {
                    break;
                }

                if (!imageData) {
                    continue;
                }

                let url = imageData.url;
                const alt = imageData.alt;
                const width = imageData.width;
                const height = imageData.height;
                const badgeHTML = Badge.displayBadge(badgeData);
                const techBadgeHTML = Badge.displayTechBadge(pdpTechBadgeData);
                let swiperSlideClass = '';
                let $swiperSlide;
                let $swiperImg;
                let $zoomControls;
                let $styliticsLink;

                if (url.indexOf('scene7') > 0 && url.indexOf('?') <= 0) {
                    url += pdpImgUrlParams;
                }

                if (idx === 0) {
                    swiperSlideClass = isThumbnail ? 'swiper-slide-thumb-active' : 'swiper-slide-active';
                } else if (idx === 1) {
                    swiperSlideClass = 'swiper-slide-next';
                }

                if (imageData.videoUrl) {
                    swiperSlideClass += ' swiper-slide-video';
                } else {
                    swiperSlideClass += ' js-product-image';
                }

                $swiperSlide = $(`<li class="swiper-slide ${swiperSlideClass}" style="${swiperSlideStyle}" data-index="${idx}"></li>`);

                if (imageData.videoUrl) {
                    if (isThumbnail) {
                        $swiperImg = $(`
                            <picture>
                                <img
                                    src="${imageData.url}"
                                    alt="Thumbnail: ${imageData.productVideoAltText || alt}"
                                    itemprop="image" />
                            </picture>
                            <svg class="svgicon video-icon text-white"><use href="#play"></use></svg>
                        `);
                    } else {
                        $swiperImg = $(`
                            <video autoplay loop playsinline muted poster="${imageData.posterUrl}" class="img-fluid" name="${imageData.productVideoAltText || alt}">
                                <source src="${imageData.videoUrl}" type="video/mp4" />
                            </video>
                        `);
                    }
                } else {
                    var onModelAttribute = imageData.modelData ? `data-onmodel="${encodeURIComponent(imageData.modelData)}"` : '';

                    // Do not attach zoom controls for QuickView
                    $zoomControls = !isQuickView && !isThumbnail ? $(`
                        <button class="swiper-controls__zoom btn-icon js-pdp-gallery-zoom">
                            <svg class="swiper-controls__icon-zoom--in" width="24" height="24">
                                <use href="#zoom_in"></use>
                            </svg>
                            <svg class="swiper-controls__icon-zoom--out" width="24" height="24">
                                <use href="#zoom_out"></use>
                            </svg>
                        </button>
                    `) : null;

                    // Do not attach stylitics jumplink for QuickView
                    $styliticsLink = enableStylitics && !isQuickView && !isThumbnail ? $(`
                        <div class="stylitics-jumplink js-stylitics-jumplink">
                            <span class="stylitics-jumplink-text">
                                ${window.resources.stylitics.jumplinkText}
                            </span>
                            <svg class="svgicon" width="24" height="24">
                                <use href="#expand_more"></use>
                            </svg>
                        </div>
                    `) : '';

                    if (isThumbnail) {
                        $swiperImg = $(`
                            <img
                                src="${url}"
                                srcset="${retinaEnabled && imageData.retinaUrl ? imageData.retinaUrl + ' 2x' : ''}"
                                alt="${'Thumbnail: ' + alt + ', image ' + imageAltIdx++}"
                                class="js-model-image"
                                itemprop="image"
                                data-lowres="${url}"
                                data-hires="${url.split('?')[0] + hiresImgUrlParams}"
                                ${onModelAttribute}
                                width="${width}"
                                height="${height}"
                                onerror="window.imageError(this, ${retinaEnabled ? '\'retina\'' : '\'large\''})"
                            />
                        `);
                    } else {
                        $swiperImg = $(`
                            <div class="slide-container">
                                ${badgeHTML}
                                ${techBadgeHTML}
                                <div class="swiper-zoom-container">
                                    <img
                                        src="${url}"
                                        srcset="${retinaEnabled && imageData.retinaUrl ? imageData.retinaUrl + ' 2x' : ''}"
                                        alt="${alt + ', image ' + imageAltIdx++}"
                                        class="js-model-image"
                                        itemprop="image"
                                        data-lowres="${url}"
                                        data-hires="${url.split('?')[0] + hiresImgUrlParams}"
                                        ${onModelAttribute}
                                        width="${width}"
                                        height="${height}"
                                        onerror="window.imageError(this, ${retinaEnabled ? '\'retina\'' : '\'large\''})"
                                    />
                                </div>
                            </div>
                        `);
                    }
                }

                $swiperImg.append($zoomControls);
                $swiperImg.append($styliticsLink);
                $swiperSlide.append($swiperImg);
                $swiperSlide.appendTo($swiperWrapper);
            }

            this.swiper.update();
            this.swiper.slideTo(0);
        });

        $('body').trigger('product:afterImageGalleryUpdate');
    }
}

/**
 * Create sketchfab asset
 * @param {Object} assetData - assets to be updated
 * @param {boolean} isThumbnail - if asset is a thumbnail
 * @return {jQuery} $asset
 */
function createSketchfabAsset(assetData, isThumbnail) {
    let $asset;

    if (isThumbnail) {
        $asset = $(`
            <li class="swiper-slide">
                <svg class="img-fluid"><use xlink:href="#360icon"></use></svg>
            </li>
        `);
    } else {
        $asset = $(`
            <li id="pdp3Dsketch" class="swiper-slide">
                <iframe class="lazy" width="640" height="480" src="https://sketchfab.com/models/${assetData.modelId}/embed" frameborder="0" allow="autoplay; fullscreen; vr" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </li>
        `);
    }

    return $asset;
}

/**
 * Create video asset
 * @param {Object} assetData - assets to be updated
 * @param {boolean} isThumbnail - if asset is a thumbnail
 * @return {jQuery} $asset
 */
function createVideoAsset(assetData, isThumbnail) {
    let $asset;
    let $swiperContent;

    if (isThumbnail) {
        $asset = $(`
            <li class="swiper-slide swiper-slide-video">
                <svg class="svgicon video-icon text-white"><use href="#play"></use></svg>
            </li>
        `);
    } else {
        $asset = $(`<li class="swiper-slide swiper-slide-video js-video-player video-play-button" data-video-id="${assetData.videoID}" data-host="${assetData.videoType}"></li>`);
    }

    $swiperContent = $(`
        <picture>
            <img
                src="${assetData.productVideoThumbnail}"
                alt="${assetData.productVideoThumbnailAltText}"
                itemprop="image" />
        </picture>
    `);

    $asset.prepend($swiperContent);

    return $asset;
}

/**
 * Create asset slide and thumbnail
 * @param {Object} assetData - assets to be updated
 * @param {string} assetType - Asset type: video or sketchfab
 */
function createSwiperAssets(assetData, assetType) {
    const $imageGalleries = $mainPDPGallery.closest('.primary-images').find('.js-product-gallery, .js-product-gallery-thumb');

    $imageGalleries.each(function () {
        const $imageGallery = $(this);
        const $wrapper = $imageGallery.find('.swiper-wrapper');
        const isThumbnail = $imageGallery.hasClass('js-product-gallery-thumb');
        let $swiperSlide;

        if (assetType === 'sketchfab') {
            $swiperSlide = createSketchfabAsset(assetData, isThumbnail);
        } else if (assetType === 'video') {
            $swiperSlide = createVideoAsset(assetData, isThumbnail);
        } else {
            return;
        }

        $wrapper.append($swiperSlide);
        this.swiper.update();
    });
}

/**
 * Create asset element
 * @param {Object} assetData - asset to be updated
 * @param {string} assetType - Asset type: video or sketchfab
 * @param {Object} $productContainer - for product container
 */
function createAssets(assetData, assetType) {
    if (assetData) {
        createSwiperAssets(assetData, assetType);
    }
}

/**
 * Create image container and hide existing images on products
 * @param {Object} images - images to be updated
 * @param {Object} $productContainer - Product Details container reference
 */
function createImagesAndVideo(images, $productContainer) {
    // Update primary images
    if (images) {
        onColorVariantHover(images, $productContainer);
    }
    var altText = window.productThumbnailAltText ? window.productThumbnailAltText : 'null';
    if (window.product_VideoData) {
        var productVideoData = window.product_VideoData;
        var videoData = {
            videoType: productVideoData.videoType,
            videoID: productVideoData.videoID,
            productVideoThumbnail: productVideoData.productVideoThumbnail,
            productVideoThumbnailAltText: altText
        };
        createAssets(videoData, 'video');
    }

    // re-initialize video player after swiper update
    $(document).trigger('video:player:update');
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 * @param {Object} selectedAttributeID - attribute being updated
 */
function handleVariantResponse(response, $productContainer, selectedAttributeID) {
    const isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    const $omnibusPrice = $('.omnibus-price');
    let isVariant;

    if (response.product.variationAttributes) {
        updateAttrs(response.product, $productContainer);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    if (selectedAttributeID === 'color' && !window.resources.enableServerSideGallery) {
        const primaryImage = response.product.images.large;

        // Update quickview/PDP primary images
        if ($('.quickview-modal').length) {
            createImagesAndVideo(primaryImage, $('.product-quickview'));
        } else if ($mainPDPGallery.length) {
            createImagesAndVideo(primaryImage, $('.product-detail'));
        }

        // Reset notify me status on variant swatches
        setVariantNotifyMeStatus();
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        const $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update Omnibus pricing
    if (!response.product.lowestPrice30Days) {
        $omnibusPrice.empty();
    } else if ($omnibusPrice.length) {
        $omnibusPrice.html(response.product.lowestPrice30Days.html);
    }

    // Update promotions
    $('.promotions').empty().html(getPromotionsHtml(response.product.promotions));

    updateAvailability(response, $productContainer, selectedAttributeID);

    if (isChoiceOfBonusProducts) {
        const $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }
}


/**
 * Get the selected swatches from the DOM. This data is used as a fallback to
 * retain the current user selections. Exclude the clicked attribute.
 *
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {jQuery} selectedAttributeID - The attribute that was last clicked (so we can exclude it)
 * @return {Object} - The results
 */
function getUserSelectedAttributes($productContainer, selectedAttributeID) {
    var colorValue = $productContainer.find('.js-color-swatch.selected span').data('attr-value');
    var sizeValue = $productContainer.find('.js-size-swatch.selected').data('attr-value');
    var dimensionValue = $productContainer.find('.js-dimension-swatch.selected').data('attr-value');
    var obj = {};
    if (colorValue !== undefined || sizeValue !== undefined || dimensionValue !== undefined) {
        if (colorValue !== undefined && selectedAttributeID !== 'color') {
            obj['selection.color'] = colorValue;
        }
        if (sizeValue !== undefined && selectedAttributeID !== 'size') {
            obj['selection.size'] = sizeValue;
        }
        if (dimensionValue !== undefined && selectedAttributeID !== 'dimension') {
            obj['selection.dimension'] = dimensionValue;
        }
    }
    return obj;
}

/**
 * dimension value append in URL parameter
 *
 * @param {string} selectedAttrUrl - the Url for the selected variation value
 * @return {string} - new selected variation value url
 */
function appendDimensionParams(selectedAttrUrl) {
    var $url = selectedAttrUrl;
    var $dimensionSwatches = $('.product-detail').find('div.js-attribute-dimension .js-dimension-swatch');
    if ($dimensionSwatches.length > 0) {
        if ($dimensionSwatches.length === 1 && $dimensionSwatches.hasClass('selected')) {
            $url = $url + '&dwvar_' + $('.product-detail').data('pid') + '_dimension=' + $dimensionSwatches.data('attr-value');
        } else if ($dimensionSwatches.length > 1) {
            var $selectedAttr = $('.product-detail').find('div.js-attribute-dimension .js-dimension-swatch.selectable');
            if ($selectedAttr.length === 1 && $selectedAttr.hasClass('selected')) {
                $url = $url + '&dwvar_' + $('.product-detail').data('pid') + '_dimension=' + $selectedAttr.data('attr-value');
            } else {
                $selectedAttr = $('.product-detail').find('div.js-attribute-dimension .js-dimension-swatch.unselectable');
                var len = $dimensionSwatches.length - $selectedAttr.length;
                if (len === 1) {
                    $selectedAttr = $('.product-detail').find('div.js-attribute-dimension .js-dimension-swatch.selected');
                    if ($selectedAttr.length === 1) {
                        $url = $url + '&dwvar_' + $('.product-detail').data('pid') + '_dimension=' + $selectedAttr.data('attr-value');
                    }
                }
            }
        }
    }
    return $url;
}

/**
 * updates the product view when a product attribute is selected or deselected
 *
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {string} selectedAttributeID - The ID of the swatch that was clicked (color, size, sizeType, dimension)
 */
function attributeSelect(selectedValueUrl, $productContainer, selectedAttributeID) {
    if (selectedValueUrl) {
        const variantData = getUserSelectedAttributes($productContainer, selectedAttributeID);
        const galleryURL = (selectedAttributeID === 'color' && $('.js-color-swatch[data-attr-url="' + selectedValueUrl + '"]').data('attr-gallery-url')) || null;

        $('body').trigger('product:beforeAttributeSelect', { url: selectedValueUrl, container: $productContainer });

        if (selectedAttributeID === 'color' && window.resources.enableServerSideGallery && galleryURL) {
            $.ajax({
                url: galleryURL,
                method: 'GET',
                data: variantData,
                success: function (data) {
                    const $$productGalleryContainer = $productContainer.find('.primary-images');

                    if (data && $$productGalleryContainer.length) {
                        const $productGallery = $$productGalleryContainer.find('.js-product-gallery');
                        const $productGalleryThumb = $$productGalleryContainer.find('.js-product-gallery-thumb');

                        if ($productGallery.length && $productGalleryThumb.length) {
                            $productGallery[0].swiper.destroy();
                            $productGalleryThumb[0].swiper.destroy();
                        }

                        $$productGalleryContainer.replaceWith(data);
                        $(document).trigger('product:swiper:update');
                    }
                }
            });
        }

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            data: variantData, // This data used as fallback to retain current user selections
            success: function (data) {
                if (data) {
                    handleVariantResponse(data, $productContainer, selectedAttributeID);
                    if (window.preQuickViewModalDigitalData && 'dtmLayer' in data && 'digitalData' in window) {
                        // Updating only the pageURL and product object in the digital data when variations are selected from the quick view modal.
                        if (data.dtmLayer.page && window.digitalData.page) {
                            window.digitalData.page.pageInfo.pageURL = data.dtmLayer.page.pageInfo.pageURL;
                            window.digitalData.page.product = data.dtmLayer.page.product;
                        }
                    } else if ('dtmLayer' in data && 'digitalData' in window) {
                        if (data.dtmLayer.page) {
                            window.digitalData.page = data.dtmLayer.page;
                        }
                        if (data.dtmLayer.user) {
                            window.digitalData.user = data.dtmLayer.user;
                        }
                        if (data.dtmLayer.cart) {
                            window.digitalData.cart = data.dtmLayer.cart;
                        }
                    }

                    $('body').trigger('product:afterAttributeSelect', { data: data, container: $productContainer });

                    // using native JS .dispatchEvent() rather than jQuery .trigger()
                    // so that Adobe Launch can listen for this custom event.
                    document.body.dispatchEvent(new CustomEvent('product:afterAttributeSelect:dtm', {
                        detail: {
                            // data: data, // optionally, we can send the data to Launch rather than have it pull it from digitalData
                            attributeType: selectedAttributeID
                        }
                    }));

                    updateOOSColorVariants($productContainer);
                    onModel.triggerOnModelDescription();
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $.spinner().start();
    $('#chooseBonusProductModal').remove();

    const bonusUrl = data.bonusChoiceRuleBased ? data.showProductsUrlRuleBased : data.showProductsUrlListBased;
    const htmlString = utils.createModalMarkup('', {
        modalId: 'chooseBonusProductModal',
        title: data.labels.selectprods,
        customClasses: 'choose-bonus-product-dialog'
    });
    $('body').append(htmlString);
    $('#chooseBonusProductModal .modal-dialog').data({
        'total-qty': data.maxBonusItems,
        UUID: data.uuid,
        pliUUID: data.pliUUID,
        addToCartUrl: data.addToCartUrl,
        pageStart: 0,
        pageSize: data.pageSize,
        moreURL: data.showProductsUrlRuleBased,
        bonusChoiceRuleBased: data.bonusChoiceRuleBased
    });

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'html',
        success: function (html) {
            var parsedHtml = parseHtml(html);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    if (response && $.isNumeric(response.quantityTotal)) {
        $(document).trigger('minicart:updatecount', response.quantityTotal);
    }

    // vars defined to make if/else logic easier to read
    let isReservable = response.reservableProduct;
    let isMobile = breakpoints.isLowRes();
    let minicartAction = response.minicartAction || '';
    let hasError = response.error;

    if (isReservable) {
        // reservation banner
        let reservationTimerHelper = require('../components/reservationTimer');
        reservationTimerHelper.updateReservationData(response);
    } else if ((isMobile && minicartAction !== 'drawer') || hasError) {
        // UX wants specific button styles per brand.
        const siteID = clientData && clientData.site && clientData.site.id;
        const buttonClass = siteID && (siteID.includes('Columbia') || siteID.includes('MountainHardwear')) ? 'btn-secondary' : 'btn-tertiary';
        // standard mobile or error banner
        const responseTitle = response.title ? `<p class="mb-3 font-weight-bold">${response.title}</p>` : '';
        const alertBannerMessage = responseTitle + `<p class="${!hasError ? 'mb-3' : 'mb-0'}">${response.message}</p>`;
        const continueShoppingBtn = response.continueShoppingBtn ? `<div class="col-12 col-md-4 mb-2"><button class="btn btn-block ${buttonClass} js-alert-close px-3">${response.continueShoppingBtn}</button></div>` : '';
        const viewCartBtn = response.viewCartUrl ? `<div class="col-12 col-md-4 mb-2"><a class="btn btn-block ${buttonClass}" href="${response.viewCartUrl}">${response.addToCartBtn}</a></div>` : '';
        const checkoutBtn = response.checkoutUrl ? `<div class="col-12 col-md-4 mb-1"><a class="btn btn-block btn-checkout bfx-remove-element" href="${response.checkoutUrl}">${response.checkoutBtn}</a></div>` : '';
        const alertBannerContent = hasError ? alertBannerMessage : `${alertBannerMessage}
            <div class="row">
                ${continueShoppingBtn}
                ${viewCartBtn}
                ${checkoutBtn}
            </div>`;
        const alertBannerOptions = {
            content: alertBannerContent,
            placement: 'banner',
            theme: hasError ? 'danger' : 'primary'
        };
        $.alert(alertBannerOptions);
    } else {
        // minicart
        $(document).trigger('minicart:show');

        var hideMinicartTimeout = setTimeout(function () {
            $(document).trigger('minicart:hide');
        }, 7000);

        $('body').one('mouseenter', '.js-minicart-popover', function () {
            clearTimeout(hideMinicartTimeout);
        });
        $('body').one('mouseenter', '.minicart-drawer', function () {
            clearTimeout(hideMinicartTimeout);
        });
    }

    if ('_satellite' in window && 'dtmEvent' in response) {
        if (!!response.dtmEvent && response.dtmEvent.length) {
            response.dtmEvent.forEach((action) => {
                _satellite.track(action.event, action.data); //eslint-disable-line
            });
        }
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').data('pid'),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Test to see if all available product attributes have been selected
 *
 * @param {jQuery} $addToCartButton - add to cart button that was clicked
 * @returns {boolean} Have all needed selections been made?
 */
function isEverythingSelected($addToCartButton) {
    let $swatchAttributes;
    const commonParentSelector = '.js-product-content';
    const swatchGroupSelectors = '.js-color-attribute, .js-size-attribute, .js-dimension-attribute';

    if ($addToCartButton) {
        $swatchAttributes = $addToCartButton.closest(commonParentSelector).find(swatchGroupSelectors);
    } else {
        $swatchAttributes = $(swatchGroupSelectors);
    }

    let allAttributesSelected = true;
    $swatchAttributes.each(function () {
        let $thisAttribute = $(this);
        if (!$thisAttribute.find('.selected').length) {
            allAttributesSelected = false;
        }
    });

    return allAttributesSelected;
}

/**
 * Add positioning class to popup if necessary.
 * @param {jQuery} $el - DOM element parent element
 * @param {jQuery} $popup - DOM element current popup
 */
function popupAlignment($el, $popup) {
    var viewportWidth = $(window).width();
    var positionLeft = $el.offset().left;
    var elementWidth = $el.outerWidth();
    var elementCenter = positionLeft + (elementWidth / 2);
    var popupHalfWidth = $popup.outerWidth() / 2;
    var left = elementCenter <= popupHalfWidth;
    var right = popupHalfWidth > (viewportWidth - elementCenter);

    $popup[left ? 'addClass' : 'removeClass']('left-arrow');
    $popup[right ? 'addClass' : 'removeClass']('right-arrow');
}

/**
 * Prana Only: Show out of stock popup on size swatch
 * @param {jQuery} $sizeSwatch - DOM element
 * @param {string} eventType - Event type
 */
function showOutOfStockPopup($sizeSwatch, eventType) {
    // TODO: Needs information about "Shop Our Partners" functionality.
    var $popup = $sizeSwatch.find('.product-outofstock__popup');

    if ($popup.length) { // This only applicable for Prana
        var thisSwatch = $sizeSwatch;

        if (eventType === 'addToCart') {
            // Desktop/Mobile: Show "Sold Out" popup after clicking these "Add to Cart/Out of Stock" button.
            popupAlignment($sizeSwatch, $popup);
            $popup.removeClass('invisible');
            setTimeout(function () {
                $(thisSwatch).find('.product-outofstock__popup').addClass('invisible');
            }, 3000);
        } else if (breakpoints.isLowRes()) {
            // Mobile: Opens on click.
            if (eventType === 'click') {
                popupAlignment($sizeSwatch, $popup);
                $popup.removeClass('invisible');
                $(document).one('click', function () {
                    $(thisSwatch).find('.product-outofstock__popup').addClass('invisible');
                });
            }
        } else {
            // Desktop: Opens on hover.
            if (eventType === 'mouseenter') { // eslint-disable-line
                popupAlignment($sizeSwatch, $popup);
                $popup.removeClass('invisible');
            } else if (eventType === 'mouseleave') {
                popupAlignment($sizeSwatch, $popup);
                $popup.addClass('invisible');
            }
        }
    }
}

/**
 * Restores the digitalData object.
 * @param {Object} data The data to set in the digitalData object
 */
function restoreDTM(data) {
    if (!!data && 'digitalData' in window) {
        const propsToUpdate = ['pageInstanceID', 'page', 'user'];
        propsToUpdate.forEach((prop) => {
            if (data[prop]) {
                window.digitalData[prop] = data[prop];
            }
        });
        delete window.preQuickViewModalDigitalData;
    }
}

/**
 * Handle click on button.add-to-cart when it is disabled
 */
function onClickAddToCartButtonDisabled() {
    var $productContainer = $(this).closest('.product-detail');

    // Scrolls to the top of the product__attributes section if they are not all set.
    // offset to view all product attributes after alert disapears.
    var offset = $('.siteheader').outerHeight();
    $('.product__attributes').scrollTo(offset, 750); // TODO: GSD-11472 - can we just listen for the alert.closed event?

    // Reveal "Select a size" message.
    var $sizeSwatchSelected = $productContainer.find('.js-attribute-size .selected');
    var isSizeSwatchSelected = $sizeSwatchSelected.length;
    if (!isSizeSwatchSelected) {
        $('.selectswatch-size-msg', $productContainer).removeClass('d-none');
    }

    // Reveal any "Sold Out" messaging.
    if ($sizeSwatchSelected.find('.product-outofstock__popup').length) {
        // For Prana reveal the popup "Sold Out" message.
        $('body').trigger('product:showOutOfStockPopup', $sizeSwatchSelected);
    } else {
        // For other brands, reveal "Sold Out" div if they are not empty
        $('.product__message--velocity:parent', $productContainer).show();
        $('.outofstock-combination-msg:parent', $productContainer).show();
    }
    $('.accordion').trigger('collapse-changed'); // Refresh hc-sticky to avoid ovelaping content if velocity message appears.
}

/**
 * Handles disabled add to cart button interactions
 */
function disabledAddToCartHandler() {
    if ($('.add-to-cart__container .btn-add-to-cart').eq(0).prop('disabled')) { // if add to cart button is disabled
        var $dimElement = $('.js-dimension-attribute');
        var $sizeElement = $('.js-size-attribute');

        if ($dimElement.length) {
            $('.selectswatch-dimension-msg').toggleClass('d-none', !!$dimElement.find('a.selected').length);
        }

        if ($sizeElement.length) {
            $('.selectswatch-size-msg').toggleClass('d-none', !!$sizeElement.find('a.selected').length);
        }

        onClickAddToCartButtonDisabled();
    }
}

/**
 * Updates attribute label on swatch interaction
 * @param {jQuery} $swatch swatch element that was interacted with
 */
function updateSwatchAttributeLabel($swatch) {
    let selectedValue;
    const attrType = $swatch.closest('.js-attribute').data('attr');

    if (attrType === 'color') {
        selectedValue = $swatch.find('.color-value').attr('title');
    } else {
        selectedValue = $swatch.data('attr-hover');
    }

    if (selectedValue !== undefined) {
        $swatch.closest('.js-attribute').find('.js-attr-label-value').text(selectedValue);
    }
}

module.exports = {
    isEverythingSelected: isEverythingSelected,
    disabledAddToCartHandler: disabledAddToCartHandler,
    attributeSelect: attributeSelect,
    updateOOSColorVariants: updateOOSColorVariants,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },
    selectAttribute: function () {
        $('.product-detail').find('.js-attribute a.selected').each(function () {
            updateSwatchAttributeLabel($(this));
        });

        // js event listener that accepts an html swatch element and runs attributeSelect() code to select that swatch
        document.body.addEventListener('selectAttribute', function (e) {
            const $swatch = $(e.detail);

            if ($swatch) {
                const url = $swatch.data('attr-url');
                const $container = $swatch.closest('.product-detail');
                const attrType = $swatch.closest('.js-attribute').data('attr');

                attributeSelect(url, $container, attrType);
            }
        });

        $(document).on('click touchend', '.product-detail .js-swatch', function (e) {
            e.preventDefault();
            const $swatchClicked = $(e.currentTarget);

            if ($swatchClicked.not('.js-notifyme-swatch').hasClass('selected') || $swatchClicked.hasClass('invalid') || $swatchClicked.hasClass('not-orderable')) {
                return;
            }

            $swatchClicked.off('mouseleave');
            $swatchClicked.trigger('attribute:click');

            updateSwatchAttributeLabel($swatchClicked);

            let $productContainer = $swatchClicked.closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $swatchClicked.closest('.product-detail');
            }

            const selectedAttributeID = $(this).closest('.js-attribute').data('attr');

            let $selectedAttrUrl = $swatchClicked.data('attr-url');
            if (selectedAttributeID === 'size') {
                $selectedAttrUrl = appendDimensionParams($selectedAttrUrl);
            }

            attributeSelect($selectedAttrUrl, $productContainer, selectedAttributeID);
        });

        // Color Swatch hover effects
        $(document).off('mouseenter').on('mouseenter', '.product-detail .js-swatch', function (e) {
            if (breakpoints.isHighRes()) {
                const $swatchEntered = $(e.currentTarget);

                // do not reload selected swatch
                if ($swatchEntered.hasClass('selected')) return;

                const currentLabelText = $swatchEntered.closest('.js-attribute').find('.js-attr-label-value').text();

                updateSwatchAttributeLabel($swatchEntered);

                $swatchEntered.one('mouseleave', function () {
                    $swatchEntered.closest('.js-attribute').find('.js-attr-label-value').text(currentLabelText);
                });
            }
        });

        // Prana only: "Sold Out" popup which appears when hovering/clicking on an out of stock size/dimension swatch.
        $(document).on('mouseenter mouseleave click', '.product-detail .js-size-swatch.unselectable, .product-detail .js-dimension-swatch.unselectable', function (e) {
            showOutOfStockPopup($(this), e.type);
        });

        $('body').on('product:showOutOfStockPopup', function (e, sizeSwatch) {
            showOutOfStockPopup($(sizeSwatch), 'addToCart');
        });
    },
    quickViewSwatchFunctionality: function () {
        // On Page Load if a color/size/dimension is selected display the Swatch value in the label
        $('.quickview-modal .product-detail .js-attribute a.selected').each(function () {
            updateSwatchAttributeLabel($(this));
        });
    },
    availability: function () {
        /**
         * Quantity change event for the productlineitems
         * extended with pickupinstore
         */
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var searchPID = $(this).closest('.product-detail').attr('data-pid');
            var selectorPrefix = '.product-detail[data-pid="' + searchPID + '"]';

            if ($(selectorPrefix + ' .selected-store-with-inventory').is(':visible')) {
                return;
            }

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                base.attributeSelect($(e.currentTarget).find('option:selected').data('url'), $productContainer);
            }
        });
    },

    addToCart: function () {
        $(document).off('click', 'button.add-to-cart, button.add-to-cart-global').on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            var $addToCartButton = $(this);

            if (!isEverythingSelected($addToCartButton)) {
                $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').attr('disabled', true);
                disabledAddToCartHandler();
                $.spinner().stop();
            } else {
                $('body').trigger('product:beforeAddToCart', this);

                if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                    setPids = [];

                    $('.product-detail').each(function () {
                        if (!$(this).hasClass('product-set-detail')) {
                            setPids.push({
                                pid: $(this).find('.product-id').data('pid'),
                                qty: 1,
                                options: getOptions($(this))
                            });
                        }
                    });
                    pidsObj = JSON.stringify(setPids);
                }

                pid = getPidValue($(this));

                var $productContainer = $(this).closest('.product-detail');
                if (!$productContainer.length) {
                    $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                }

                addToCartUrl = getAddToCartUrl();

                var form = {
                    pid: pid,
                    pidsObj: pidsObj,
                    childProducts: getChildProducts(),
                    quantity: 1
                };

                if (!$('.bundle-item').length) {
                    form.options = getOptions($productContainer);
                }

                $(this).trigger('updateAddToCartFormData', form);
                if (addToCartUrl) {
                    $.ajax({
                        url: addToCartUrl,
                        method: 'POST',
                        data: form,
                        success: function (data) {
                            $.spinner().stop();
                            handlePostCartAdd(data);
                            if (!data.error) {
                                $('body').trigger('product:afterAddToCart', data);
                                window.dispatchEvent(new Event('cart:updated:dtm'));
                            }
                            if (window.preQuickViewModalDigitalData) {
                                // Restoring the digital data back to that of CLP page before opening the quick view modal.
                                window.preQuickViewModalDigitalData = JSON.parse(window.preQuickViewModalDigitalData);
                                setTimeout(function () {
                                    restoreDTM(window.preQuickViewModalDigitalData);
                                }, 1000);
                            } else if (data.dtmLayer && 'digitalData' in window) {
                                if (data.dtmLayer.page) {
                                    window.digitalData.page = data.dtmLayer.page;
                                }
                                if (data.dtmLayer.user) {
                                    window.digitalData.user = data.dtmLayer.user;
                                }
                            }
                            if (data.dtmLayer && data.dtmLayer.cart && 'digitalData' in window) {
                                window.digitalData.cart = data.dtmLayer.cart;
                            }
                        },
                        error: function () {
                            $.spinner().stop();
                        }
                    });
                }
            }
        });
    },
    getPidValue: getPidValue,
    setVariantNotifyMeStatus: setVariantNotifyMeStatus,
    forceOOSNotifyMe: forceOOSNotifyMe,
    overviewInit: Overview.init()
};
