/* global registerButtonAsRecaptchaTrigger */
'use strict';

const rewardsTracker = require('./rewardsTracker');
const keyboardAccessibility = require('./keyboardAccessibility');
const utils = require('./utils');
const initProfilingCpi = require('./progressiveProfiling').initProfilingCpi;

const $siteHeaderAccount = $('.siteheader__account');

const openAccountDrawerKey = 'oad';
const signInKey = 'signin';
let openAccountDrawer = false;

let $accountDrawer;

/**
 * Private Function: Store Email id of customer in session
 * @param {Object} event JQuery event object
 */
function registerForgotPassword(event) {
    event.preventDefault();
    var $currentElement = $(event.currentTarget);
    var emailId = $currentElement.closest('form').find('input[name=loginEmail]').val();
    if (emailId && emailId.length && !window.IsKiosk) {
        sessionStorage.setItem('userEmailId', emailId);
    }
    window.location = $currentElement.attr('href');
}

/**
 * Register Event Listeners for the Registration Drawer
 */
function initRegistrationDrawerListeners() {
    $accountDrawer.on('click', '.js-btn-registration', function () {
        $accountDrawer.find('.js-rewards-terms').prop('checked', !$(this).hasClass('js-btn-registration-no-rewards'));
    });
}

/**
 * Sets or clears the redirect data attributes on the Log In and Sign Up forms.
 * @param {string} redirect The url to be redirected to after loging in or signing up.
 */
function setFormRedirectData(redirect) {
    $accountDrawer.find('form.login').data('redirect', redirect);
    $accountDrawer.find('form.registration').data('redirect', redirect);
}

/**
 * Initialize the Authorization Gateway Wizard
 * @param {string} redirect The url to be redirected to after loging in or signing up.
 */
function initAuthWizard(redirect) {
    const $authWizard = $('.js-auth-wizard');
    $authWizard.wizard({
        init: function ($wiz) {
            const $authGatewayForm = $wiz.find('form.authGateway');
            $authGatewayForm.trigger('form:created');

            // Register Auth Gateway form's submission button
            $wiz.on('submit', 'form.authGateway', function (e) {
                e.preventDefault();
                $accountDrawer.spinner().start();

                let isSuccess;

                $.ajax({
                    url: $authGatewayForm.attr('action'),
                    type: 'post',
                    data: $authGatewayForm.serialize(),
                    success: function (response) {
                        // Handle bad input
                        let $invalidField = $authGatewayForm.find('.invalid-feedback');
                        if (response.error) {
                            $invalidField.text(response.msg).show();
                            return;
                        }
                        $invalidField.text('').hide();

                        const $signInOrCreateAccountStep = $wiz.find('[data-step-id=signInOrCreateAccount]');
                        $signInOrCreateAccountStep.empty();
                        $signInOrCreateAccountStep.append($(response));

                        // Identify the form shown & set up respective listeners
                        let isLoginFlow = $('.account-drawer__login').length;

                        if (isLoginFlow) {
                            $accountDrawer.find('form.login').trigger('form:created');
                        } else {
                            $accountDrawer.find('form.registration').trigger('form:created');
                            initRegistrationDrawerListeners();
                        }

                        // Set form redirect data regardless of form provided
                        if (redirect) {
                            setFormRedirectData(redirect);
                        }

                        isSuccess = true;
                    },
                    complete: function () {
                        $accountDrawer.spinner().stop();

                        if (isSuccess) {
                            /**
                             * formsDataLayer.js depends upon the 'ajaxSuccess' event.
                             * As such, we should not move to the next step until the ajax call is 'complete'
                             * to avoid timing issues. Especially with form field focus, which updates the data layer.
                             */
                            $wiz.nextStep();
                        }
                    }
                });
            });

            $wiz.on('click', '.js-auth-wizard-back', function () {
                $wiz.prevStep();
            });
        },
        afterStepEnter: function (stepId, $wiz) {
            // After steping into the second step, focus on the first non-email input
            if (stepId === 'signInOrCreateAccount') {
                const $signInOrCreateAccountStep = $wiz.find('[data-step-id=signInOrCreateAccount]');
                // For Login, find the password field. For Registration, find first name
                const selector = $('.account-drawer__login').length ? 'input[type=password]' : 'input#firstName';

                $signInOrCreateAccountStep.find(selector).focus();
            }
        }
    });
}

/**
 * Open Account Drawer
 * @param {event} drawerEvent The click event that called this funtion
 */
function openDrawer(drawerEvent) {
    if ($siteHeaderAccount.hasClass('js-drawer-shown')) {
        // drawer is already shown
        return;
    }

    const redirect = (drawerEvent && $(drawerEvent.currentTarget).data('redirect')) || '';

    $siteHeaderAccount.addClass('js-drawer-shown');
    if ($accountDrawer) {
        // the account drawer has already been created. Just show it.
        $accountDrawer.open();
        setFormRedirectData(redirect);
    } else {
        $accountDrawer = $.drawer({
            src: $('.siteheader').data('lazy-load-account-drawer-url'),
            size: 'sm',
            class: 'account-drawer',
            destroyOnClose: false,
            returnFocusTo: drawerEvent ? drawerEvent.currentTarget : 'body'
        }).on('hide.drawer', function (e, closeEvent) {
            $siteHeaderAccount.removeClass('js-drawer-shown');

            // also close mobile nav
            const $clickedButton = closeEvent && $(closeEvent.currentTarget);
            if (!($clickedButton && $clickedButton.hasClass('js-action__menuback'))) {
                // if we click the mobile "back" button, just close the account drawer.
                // otherwise, also close the mobile nav drawer.
                $('.sitenav').closeMenuItem();
                $('html').removeClass('menu-active');
                $.enableScroll();

                // SOREL: also toggle the close button back to hamburger menu
                $('.js-action__menutoggle').removeClass('close');
            }
        }).on('shown.drawer', function () {
            window.dispatchEvent(new Event('accountPopover:shown:dtm'));
            window.dispatchEvent(new Event('accountDrawer:shown:dtm'));
        }).on('contentAsyncLoaded.drawer', function () {
            // Identify whether the user is signed in or not, and load the appropriate modules
            let showAuthWizard = $('.js-auth-wizard').length;
            if (showAuthWizard) {
                initAuthWizard(redirect);

                // Set up listener to remove 'oad' queryparam if registration form is submitted
                if (openAccountDrawer) {
                    $('body').one('login:register:success', function (e, data) {
                        if (data.redirectUrl) {
                            // eslint-disable-next-line no-param-reassign
                            data.redirectUrl = utils.removeQueryParam(openAccountDrawerKey, data.redirectUrl).toString();
                        }
                        utils.removeQueryParamFromCurrentUrl(openAccountDrawerKey);
                    });
                }
            } else {
                // Progressive Profiling Setup
                initProfilingCpi($(this).find('.progress'));

                // Load My Account Drawer Content
                rewardsTracker.loadRewardsTracker($('.account-content'));

                // After user is logged in, prevent additional openings of account drawer
                if (openAccountDrawer) {
                    utils.removeQueryParamFromCurrentUrl(openAccountDrawerKey);
                }
            }
        });
    }
}

/**
 * Keyboard events
 */
function keyboardEvents() {
    keyboardAccessibility('.navbar-header .siteheader__account',
        {
            // Escape key (27) is handled below - ESC on any element will close the drawer if it's open.
            38: function () { // up
                $accountDrawer.close();
                return true;
            },
            40: function () { // down
                openDrawer();
                return true;
            }
        },
        function () {
            return this;
        }
    );

    $(document).on('keydown', function (e) {
        if (e.key === 'Escape' && $siteHeaderAccount.hasClass('js-drawer-shown')) {
            $accountDrawer.close();
        }
    });
}

module.exports = {
    init: function () {
        keyboardEvents();

        // Open the Account Drawer if the request contains a queryparam to do so
        const searchParams = new URLSearchParams(window.location.search);
        let openForSignIn = searchParams.has(signInKey);
        openAccountDrawer = searchParams.has(openAccountDrawerKey);
        if (openAccountDrawer || (openForSignIn && !window.clientData.isLoggedIn)) {
            openDrawer();
        }

        // Remove signin from queryparam if present
        if (openForSignIn) {
            utils.removeQueryParamFromCurrentUrl(signInKey);
        }

        $(document)
            .on('click', '.js-drawer-login, .js-drawer-registration, .js-action__account', function (e) {
                e.preventDefault();
                openDrawer(e);
            })
            .on('accountDrawer:show', function () {
                openDrawer();
            })
            .on('accountDrawer:hide', function () {
                $accountDrawer.close();
            })
            .on('click', '.account-content .forgot-password a, #password-reset', function (e) {
                registerForgotPassword(e);
            });
    }
};
